import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { NotificationManager } from "react-notifications";
import InputField from "../technologyModal/InputFields/InputField";
import successImg from "../../images/ServiceIcons/success.svg";
import "./DemoModal.css";
// import cookie from "react-cookie";

export default function DemoModal({ show, onHide, href, id1, id2 }) {
  const [fieldValue, setFieldValue] = useState({
    company: "",
    name: "",
    mobile: "",
    email: "",
    role: "",
    leadType: "Demo",
  });

  console.log(id1, "reetyeddddddddddddddddddddddddddddddddddddddd");
  const [thanksView, setThanksView] = useState(false);

  const [role, setRole] = useState([]);
  const [validations, setValidations] = useState({
    company: "",
    name: "",
    mobile: "",
    email: "",
    role: "",
  });

  const [otpView, setOtpView] = useState(false);
  const [OTP, setOTP] = useState();
  const [OTPBtnDisabled, setOTPBtnDisabled] = useState(true);
  const handleChange = (OTP) => setOTP(OTP);
  const [clickCount, setClickCount] = useState(0);

  const otpVal = { mobile: fieldValue.mobile, otp: OTP };

  console.log(OTPBtnDisabled, "hffffffffffffffffffff");

  const validateAll = () => {
    const { company, name, email, mobile, role } = fieldValue;
    const validations = {
      company: "",
      name: "",
      email: "",
      mobile: "",
      role: "",
    };
    let isValid = true;

    if (!company) {
      validations.company = "Company Name is required";
      isValid = false;
    }

    if (!name) {
      validations.name = " Name is required";
      isValid = false;
    }

    // if (name && name.length < 3 || name.length > 50) {
    //   validations.name = 'Name must contain between 3 and 50 characters'
    //   isValid = false
    // }

    if (!email) {
      validations.email = "Email is required";
      isValid = false;
    }

    if (!role) {
      validations.role = "Please select the enterprise size";
      isValid = false;
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "Email format must be as example@mail.com";
      isValid = false;
    }

    if (!mobile) {
      validations.mobile = "Phone number is required";
      isValid = false;
    }
    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const validateOne = (e) => {
    const { name } = e.target;
    const value = fieldValue[name];
    let message = "";

    // if (!value) {
    //   message = `${name} is required`;
    // }
    if (!value && name == "company") {
      message = `Company name is required`;
    }
    if (!value && name == "name") {
      message = `Name is required`;
    }
    if (!value && name == "mobile") {
      message = `Phone number is required`;
    }

    if (!value && name == "role") {
      message = `Please select the enterprise size`;
    }

    // if (value && name === 'name' && (value.length < 3 || value.length > 50)) {
    //   message = 'Name must contain between 3 and 50 characters'
    // }

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "Please enter a valid email";
    }

    setValidations({ ...validations, [name]: message });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFieldValue((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  function handleSelectChange(event) {
    setRole(event.target.value);
    console.log(role);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateAll();

    if (!isValid) {
      return false;
    }

    // setOTPBtnDisabled(false)
    timerFunction();
    const fieldValueStringify = JSON.stringify(fieldValue);
    console.log("fieldValueStringify initial", fieldValueStringify);
    fetch(`${window.appConfig.getOtp}`, {
      method: "POST",
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: fieldValueStringify,
    })
      .then((res) => res.text())

      .then((result) => {
        if (result === "Email Already Registered.") {
          onHide();
          setFieldValue({});
          return NotificationManager.success(result);
        } else if (result === "Mobile Already Registered") {
          onHide();
          setFieldValue({});
          return NotificationManager.success(result);
        } else {
          setOtpView(true);
          return NotificationManager.success(result);
        }
        // resentOTP()
      })

      .catch((err) => console.log("err", err));
  };
  const otpSubmitHandler = (e) => {
    e.preventDefault();

    const fieldValueStringifyOTP = JSON.stringify(otpVal);
    console.log(
      fieldValueStringifyOTP,
      "fieldValueStringify fieldValueStringify"
    );
    fetch(`${window.appConfig.validateOtp}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: fieldValueStringifyOTP,
    })
      .then((res) => res.text())
      .then((result) => {
        if (result === "Lead Created") {
          setOtpView(false);
          setThanksView(true);
        } else {
          return NotificationManager.success(result);
        }
      })
      .catch((err) => console.log("err", err));
  };

  // Resend OTP

  const mobileNumber = fieldValue.mobile;

  const resendOTP = (e) => {
    e.preventDefault();

    fetch(`${window.appConfig.resendOTP}/${mobileNumber}`, {
      method: "POST",
      headers: {
        Accept: "text/plain",
      },
    })
      .then((res) => res.text())
      .then((result) => {
        if (result === "Lead Created") {
          window.location.replace(href);
        } else {
          setOTPBtnDisabled(true);
          setClickCount(clickCount + 1);
          timerFunction();
          // setThanksView(true)
          return NotificationManager.success(result);
        }
      })
      .catch((err) => console.log("err", err));
  };

  // useEffect(() => {
  //   if (clickCount < 3) {
  //     // setResetCounter(0)

  //     const resendOTPTimer = setTimeout(() => {
  //       setOTPBtnDisabled(false);
  //       console.log("insissttwtttttttttttwwwwwwwwwwwwwwww");
  //     }, resetCounter);
  //     console.log('resendOTPTimer',resendOTPTimer)
  //     return () => {
  //       clearTimeout(resendOTPTimer);
  //     };
  //   }
  // }, [ id1, id2, clickCount, otpView]);
const testFunction = () =>{
  setThanksView(false);
  setFieldValue(
  {  company:"",
    name :"",
    email: "",
    mobile: "",}
  )

}
  const timerFunction = () => {
    console.log("time start", clickCount);
    if (clickCount < 2) {
      const resendOTPTimer = setTimeout(() => {
        setOTPBtnDisabled(false);
      }, 540000);

      return () => {
        clearTimeout(resendOTPTimer);
      };
    }
  };
  const renderInput = (inputProps, index) => (
    <input
      {...inputProps}
      type="text"
      maxLength="1"
      className="custom-otp-input"
      key={index}
    />
  );
  
  return (
    <div className="technologyModal">
      <Modal
        // {...props}
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {!thanksView ? (
          <Modal.Header closeButton className="form-header">
            <Modal.Title id="contained-modal-title-vcenter">
              <h2 className="form-title mb-0 me-5">
                {!otpView
                  ? "Please Fill the Contact Form"
                  : "Please Enter OTP"}
              </h2>
            </Modal.Title>
          </Modal.Header>
        ) : (
          ""
        )}
        <Modal.Body>
          {!otpView && !thanksView ? (
            <form onSubmit={handleSubmit}>
              <div className="scrollableView">
                <div className="input-container mb-2">
                  <label className="d-block mb-1">Company Name*</label>
                  <InputField
                    type="text"
                    name="company"
                    value={fieldValue.company}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    placeholder="Please enter company name"
                    required="true"
                  />
                  <span className="d-block">{validations.company}</span>
                </div>

                <div className="input-container mb-2">
                  <label className="d-block mb-1"> Name*</label>
                  <InputField
                    type="text"
                    name="name"
                    value={fieldValue.name}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    required="true"
                    placeholder="Please enter your name"
                  />
                  <span className="d-block">{validations.name}</span>
                </div>

                <div className="input-container mb-2">
                  <label className="mb-1 d-block"> Phone Number*</label>
                  <InputField
                    type="mobile"
                    name="mobile"
                    value={fieldValue.mobile}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    required="true"
                    placeholder="Please enter mobile"
                  />
                  <span className="d-block">{validations.mobile}</span>
                </div>
                <div className="input-container mb-2">
                  <label className="mb-1 d-block">Email*</label>
                  <InputField
                    type="email"
                    name="email"
                    value={fieldValue.email}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    required="true"
                    placeholder="Please enter email"
                  />
                  <span className="d-block">{validations.email}</span>
                </div>
                <div className="input-container mb-3">
                  <label className="mb-1 d-block">Enterprise Size*</label>
                  <select
                    name="role"
                    value={fieldValue.role}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                  >
                    <option value="" disabled selected hidden>
                      Enterprise Size
                    </option>
                    <option value="Large">Large</option>
                    <option value="Medium">Medium</option>
                    <option selected value="Small">
                      Small
                    </option>
                    <option value="Micro">Micro</option>
                  </select>

                  {validations.role && (
                    <span className="d-block">{validations.role}</span>
                  )}
                </div>
              </div>

              <button className="Formbutton" type="submit">
                Submit
              </button>
            </form>
          ) : (
            <>
              {!thanksView ? (
                <form onSubmit={otpSubmitHandler} className="pe-5">
                  <div className="input-container-mod">
                    {/* <h2 className="container-heading2 mb-2"> Please enter OTP</h2> */}
                    <div className="otp-container mb-2 ">
                      <OtpInput
                        value={OTP}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        onChange={handleChange}
                        isInputNum={true}
                        renderInput={renderInput} 
                        inputStyle={{
                          border: "1px solid #666666",
                          borderRadius: "8px",
                          width: "40px",
                          height: "40px",
                          fontSize: "12px",
                          color: "#000",
                          fontWeight: "400",
                          caretColor: "#666666",
                        }}
                        focusStyle={{
                          outline: "none",
                        }}
                      />
                    </div>
                    <p className="mb-4 resend-otp">
                      Didn't Received OTP ?{" "}
                      <span
                        className={`${
                          OTPBtnDisabled ? "disabled-link" : "cursor-pointer "
                        } `}
                        onClick={resendOTP}
                      >
                        Resend OTP
                      </span>
                    </p>
                    <button className="Formbutton">Submit</button>
                  </div>
                </form>
              ) : (
                <div className="thankyou-container">
                  <img
                    src={successImg}
                    alt="Tick Mark"
                    className="d-block mx-auto"
                  />
                  <h2 className="container-heading1 text-center">Thank You</h2>
                  <p className="container-para text-center">
                    Our Executive will get into contact within 48 hours.
                  </p>
                  <button
                    onClick={() =>{
                      return (testFunction(),
                      onHide()
                      )

                      }}
                    className="Formbutton mx-auto d-block mt-4"
                    type="submit"
                  >
                    Close
                  </button>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
