import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "@material-ui/core";
import ProcessedProducts from "./ProcessedProducts";

const D2ConMarketplace = () => {
  const userType = ["FOOD-PROCESSOR"];
//   let navData = [
//     { to: "/bulk-onmarketplace", link: "For B2B Buyers",  },    { to: "/bulk-onmarketplace-sell", link: "For Sellers" }
//   ];
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="ExploreBannerContainer text-white"
        bannerText="Ensured Quality | Traceability  | Provenance"
        bannerbodycopy="Ensure food saftey across globe

        "
      />

      <div className="explore-product-wrapper">
        <Container>
        <div id="tendersContainer" className="container-fluid">
              <h3 id="Processed-Products" className="container-title  ">
                Processed Products
              </h3>
              <ProcessedProducts quote="true" type="PROCESSED-PRODUCTS" userType = {userType}/>
            </div>
        </Container>
      </div>

      <FooterComponent />
    </>
  );
};

export default D2ConMarketplace;
