import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import "./CommunityService.css";
import GbrFormsComponent from '../GBRForms/GbrFormsComponent';

export default function CommunityService() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="communityService"
          bannerText={[
            "Community Service"
          ]}/>

          <GbrFormsComponent/>
    <FooterComponent/>
    </>
  )
}
