import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import InputField from "../technologyModal/InputFields/InputField";
import { Container } from "@material-ui/core";
import MainService from "../Main/components/Main/Common/MainService";
import { Modal } from "react-bootstrap";
// https://registrystaging.gbrservice.com/AgriBusinessRegistryService/register/consumer2
// https://registrystaging.gbrservice.com/AgriBusinessRegistryService/register/NewAssetDeveloper2@gmail.com

// https://registrystaging.gbrservice.com/AgriBusinessRegistryService/register/[object%20Object]
export default function AgentForm(props) {
  const [fieldValue, setFieldValue] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    education: "",
    consent: [],
    state: "",
    district: "",
    taluk: "",
    village: "",
    age: "",
    gender: "",
    // description: "",
  });
  const assetDeveloperValue = props.adminName
  const [education, setRole] = useState([]);
  const [validations, setValidations] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    education: "",
    state: "",
    district: "",
    taluk: "",
    village: "",
    age: "",
    gender: "",
    // description: "",
  });




// alert(assetDeveloperValue)

const ParentValue = window.AppConfigData.ConsumerAdmin;

  const validateAll = () => {
    const { fname, lname, email, mobile, education, state, district, village, taluk, consent, age,  gender } = fieldValue;
    const validations = {
      fname: "",
      lname: "",
      email: "",
      mobile: "",
      education: "",
      state: "",
      district: "",
      taluk: "",
      village: "",
      age: "",
      gender: "",
      consent: [],
      // description: "",
    };
    let isValid = true;

    if (!fname) {
      validations.fname = "First name is required";
      isValid = false;
    }

    if (!lname) {
      validations.lname = "Last name is required";
      isValid = false;
    }

    if (!state) {
      validations.state = "State is required";
      isValid = false;
    }
    if (!district) {
      validations.district = "District is required";
      isValid = false;
    }
    if (!taluk) {
      validations.taluk = "Taluk is required";
      isValid = false;
    }

    if (!gender) {
      validations.gender = "Please choose gender";
      isValid = false;
    }
    if (!village) {
      validations.village = "Village is required";
      isValid = false;
    }
    if (!age) {
      validations.age = "Age is required";
      isValid = false;
    }

    
    if (!consent.length) {
      validations.consent = "Consent is required";
      isValid = false;
    }
    // if (!description && brand) {
    //   if (!description ) {
    //   validations.consent = "Please enter the detailed information";
    //   isValid = false;
    // }
    // if (name && name.length < 3 || name.length > 50) {
    //   validations.name = 'Name must contain between 3 and 50 characters'
    //   isValid = false
    // }

    if (!email) {
      validations.email = "Email is required";
      isValid = false;
    }
    if (!fieldValue.gender ) {
      validations.gender = "Please choose gender";
      isValid = false;
    }
    if (!consent) {
      validations.consent = "Consent is required";
      isValid = false;
    }
    if (!education) {
      validations.education = "Please select the highest education";
      isValid = false;
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "Email format must be as example@mail.com";
      isValid = false;
    }

    if (!mobile) {
      validations.mobile = "Phone number is required";
      isValid = false;
    }
    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const validateOne = (e) => {
    const { name } = e.target;
    const value = fieldValue[name];
    let message = "";

    // if (!value) {
    //   message = `${name} is required`;
    // }
    if (!value && name == "fname") {
      message = `First name is required`;
    }
    if (!value && name == "lname") {
      message = `Last name is required`;
    }
    if (!value && name == "mobile") {
      message = `Phone number is required`;
    }

    if (!value && name == "education") {
      message = `Please select the highest education`;
    }

    if (!value && name == "state") {
      message = `State is required`;
    }
    if (!value && name == "district") {
      message = `District is required`;
    }
    if (!value && name == "taluk") {
      message = `Taluk is required`;
    }
    if (!value && name == "village") {
      message = `Village is required`;
    }
      if (!value && name == "gender") {
      message = `Gender is required`;
    }
    if (!value && name == "gender") {
      message = `Gender is required`;
    }
    if (!value && name == "consent") {
      message = `Please select the consent`;
    }    // if (!value && name == "description") {
    //   message = `Please enter the detailed information`;
    // }
// alert(props.usertype)

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "Please enter a valid email";
    }

    setValidations({ ...validations, [name]: message });
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
  
  
   if (type === "radio" && checked) {
      setFieldValue((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    } else {
      setFieldValue((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    }
  };
  function handleSelectChange(event) {
    setRole(event.target.value);
    console.log(education);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateAll();

    if (!isValid) {
      return false;
    }

    else {
      const data = {
        parentid: assetDeveloperValue,
        username: fieldValue.email,
        firstname: fieldValue.fname,
        lastname: fieldValue.lname,
        password: "",

        usertype: "Agent",

        email: fieldValue.email,

        phone: fieldValue.mobile,

        agentid: fieldValue.email,
        state: fieldValue.state,

        // description: fieldValue.description,
        json: {
          gender: fieldValue.gender,
          age: fieldValue.age,
          district: fieldValue.district,
          taluk: fieldValue.taluk,
          village: fieldValue.village,
          education: fieldValue.education,
        },
        city: "",
        nominee: "",
        pancard: "",
        longitude: "",
        latitude: "",
        message: "",
        source: fieldValue.email,
        destination: assetDeveloperValue,
        requesttype: "jobCreation",
        accountIs: "Global",
        gst: "",
        cin: "",
        assetvertical: "",
        category: "",
        assettype: "",
        pincode: "",
        serviceprofile: "",
        roleprofile: "",
        rolepackage: "",
        isguestuser: "",
        userimagelink: "",
        aadhar: "",
        tan: "",
        country: "",
        region: "",
        role: "NULL",
        // description: fieldValue.description,
      };

      MainService.upgradeRoleRequest(
    
        ParentValue,
        data
      )
        .then((signupRes) => {
          NotificationManager.success(
            "Request Sent. We will get back to you soon"
          );
          // setTimeout(() => {
          //   this.goToHome();
          // }, 1000);
        })
        .catch((e) => {
          console.log(e);
          NotificationManager.error(e);
        });
    }
    setFieldValue({
      fname: "",
      lname: "",
      email: "",
      mobile: "",
      education: "",
      state: "",
      district: "",
      taluk: "",
      village: "",
      age: "",
      gender: "",
      consent: [],
    });
    console.log(fieldValue, "fieldValuefieldValuefieldValuefieldValue");
  };

  return (

    <div className="club-details-modal">
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="details-header text-white">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="details-title me-3 me-md-5"
        >
          Apply 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="details-body">
   

<div className="technologyModal">
            <form onSubmit={handleSubmit}>
              <div className="scrollableView">
                <div className="input-container mb-3">
                  <label className="d-block mb-1">First Name*</label>
                  <InputField
                    type="text"
                    name="fname"
                    value={fieldValue.fname}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    placeholder="Enter your first name"
                    required="true"
                  />
                  <span className="d-block">{validations.fname}</span>
                </div>

                <div className="input-container mb-3">
                  <label className="d-block mb-1">Last Name*</label>
                  <InputField
                    type="text"
                    name="lname"
                    value={fieldValue.lname}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    required="true"
                    placeholder="Please enter your name"
                  />
                  <span className="d-block">{validations.lname}</span>
                </div>

                <div className="input-container mb-3">
                  <label className="mb-1 d-block"> Phone Number*</label>
                  <InputField
                    type="mobile"
                    name="mobile"
                    value={fieldValue.mobile}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    required="true"
                    placeholder="Please enter mobile"
                  />
                  <span className="d-block">{validations.mobile}</span>
                </div>
                <div className="input-container mb-3">
                  <label className="mb-1 d-block">Email*</label>
                  <InputField
                    type="email"
                    name="email"
                    value={fieldValue.email}
                    onChange={handleInputChange}
                    onBlur={validateOne}
                    required="true"
                    placeholder="Please enter email"
                  />
                  <span className="d-block">{validations.email}</span>
                </div>

                {
                  <>
                    <div className="input-container mb-3">
                      <label className="mb-1 d-block">State*</label>
                      <InputField
                        type="text"
                        name="state"
                        value={fieldValue.state}
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        required="true"
                        placeholder="Please enter your state"
                      />
                      <span className="d-block">{validations.state}</span>
                    </div>

                    <div className="input-container mb-3">
                      <label className="mb-1 d-block">District*</label>
                      <InputField
                        type="text"
                        name="district"
                        value={fieldValue.district}
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        required="true"
                        placeholder="Please enter district"
                      />
                      <span className="d-block">{validations.district}</span>
                    </div>

                    <div className="input-container mb-3">
                      <label className="mb-1 d-block">Taluk*</label>
                      <InputField
                        type="text"
                        name="taluk"
                        value={fieldValue.taluk}
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        required="true"
                        placeholder="Please enter your taluk"
                      />
                      <span className="d-block">{validations.taluk}</span>
                    </div>
                    <div className="input-container mb-3">
                      <label className="mb-1 d-block">village*</label>
                      <InputField
                        type="text"
                        name="village"
                        value={fieldValue.village}
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        required="true"
                        placeholder="Please your village name"
                      />
                      <span className="d-block">{validations.village}</span>
                    </div>

                    <div className="input-container mb-3">
                      <label className="mb-1 d-block">Age*</label>
                      <InputField
                        type="text"
                        name="age"
                        value={fieldValue.age}
                        onChange={handleInputChange}
                        onBlur={validateOne}
                        required="true"
                        placeholder="Please your age"
                      />
                      <span className="d-block">{validations.age}</span>
                    </div>

                    <div className="input-container mb-3">
        <label className="mb-1 d-block">Gender:</label>
        <input
          className="radio-btn "
          type="radio"
          name="gender"
          id="male"
          value="male"
          onChange={handleInputChange}
          checked={fieldValue.gender === "male"}
          onBlur={validateOne}

        />
        <label htmlFor="male" className="me-4">
          Male
        </label>

        <input
          className="radio-btn "
          type="radio"
          name="gender"
          id="female"
          value="female"
          onBlur={validateOne}

          onChange={handleInputChange}
          checked={fieldValue.gender === "female"}
        />
        <label htmlFor="female">Female</label>
        {validations.gender && (
          <span className="d-block">{validations.gender}</span>
        )}
      </div>



                    <div className="input-container mb-3">
                      <label className="mb-1 d-block">
                        Highest level of education*
                      </label>
                      <select
                        name="education"
                        value={fieldValue.education}
                        onChange={handleInputChange}
                        onBlur={validateOne}
                      >
                        <option value="" disabled selected hidden>
                          Please enter your highest level of education
                        </option>
                        <option value="High School">High School</option>
                        <option value="Primary School">Primary School</option>
                        <option selected value="Post High School">
                          Post High School
                        </option>
                      </select>

                      {validations.education && (
                        <span className="d-block">{validations.education}</span>
                      )}
                    </div>

                    <div className="input-container mb-3">
                      <input
                        className="radio-btn d-inline-block me-2"
                        value="true"
                        onChange={handleInputChange}
                        type="checkbox"
                        name="consent"
                        id="Consent"
                        onBlur={validateOne}

                      />
                      <label className="d-inline">
                        As part of the interview process, we will need your
                        consent to contact you. Please click on this box to
                        confirm you give consent.
                      </label>

                      {validations.consent && (
                        <span className="d-block">{validations.consent}</span>
                      )}
                    </div>
                  </>
                }
              </div>

              <button className="Formbutton" type="submit">
                Submit
              </button>
            </form>
          </div>
      </Modal.Body>
    </Modal>
  </div>
  );
}
