import React from "react";
import GovernmentOrganizationImg from "../../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../../images/gbrHome/advisor.webp";
import InvestorImg from "../../../images/gbrHome/investor.webp";
import MachineryImg from "../../../images/gbrHome/machinery.webp";
import ExporterImg from "../../../images/gbrHome/exporter.webp";
import MSMEImg from "../../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";
import Advertisement from "../../gbrEngage/Advertisement/Advertisement";
import AdvisaryComponent from "../../gbrEngage/Advisary/Advisary";
import TendersComponent from "../../gbrEngage/Tenders/Tenders";
import ProcessedProducts from "../../ExploreProducts/ProcessedProducts";
import JobsComponent from "../../gbrEngage/Jobs/Jobs";
import PriceDiscovery from "../../gbrEngage/PriceDiscovery/PriceDiscovery";
import ClubUpdates from "../../gbrEngage/ClubUpdates/ClubUpdates";
import TransporterImg from "../../../images/gbrCBN/CBNImg7.webp";
import StorageProvider from "../../gbrEngage/StorageProvider/StorageProvider"
import SiteWeatherManagement from "../../SiteWeatherManagement/SiteWeatherManagement";

// import GovtSchemesComponentABN from "../../gbrEngage/GovtSchemes/GovtSchemesComponentABN";
import GovtSchemesComponentABN from "../../gbrEngage/GovtSchemes/GovtSchemesABN"
import SiteUpdates from "../../gbrEngage/SiteUpdates/SiteUpdates";
import AgriInvestor from "../../gbrEngage/AgriInvestor/AgriInvestor";
import Transporters from "../../gbrEngage/Transporters/Transporters";
import AdvertisementCBN from "../../gbrEngage/Advertisement/AdvertisementCBN";
import BuyInput from "../../gbrEngage/BuyInput/BuyInput";
import AgribusinessClub from "../../Club/AgribusinessClub";

export default function CommunitySection(props) {
  const exploreButton = props.exploreBtn;
  const extraData = props.extraData;
  const AgroDealers = ["AHSP"]

  return (
    <div className="subSectionWrapper">
      <Container>
        {/* <h4 className="container-title text-center mx-md-4 ">
          {exploreButton
            ? "PARTNERS & THEIR SERVICES"
            : "Partners"}
        </h4> */}

      {/* {!extraData &&   <p className="container-para text-center mx-md-4 mb-4">
          FEN is Digital Transformation Network for brands to maximize the
          profit. It is provided to brands as online service (SaaS) to build
          Local community, Production network and Global network.
        </p>} */}
      </Container>
      {/* {exploreButton && (
        <h3 class="container-title text-center subSectionWrapper pb-0">Advisory</h3>
      )}

      <HomeSubSection
        id="FEN-Advisory"
        className="rowReverseAbout--mod borderedCards"
        className3="image1"
        description="Advisors at FEN are essential partners, providing expertise in animal health, plant health, and machinery. Their insights ensure the well-being of livestock, optimize crop management, and maintain efficient machinery operations. Committed to excellence, these advisors play a pivotal role in FEN's holistic approach to agriculture, contributing to overall farm success.
                "
        descriptionTitle="
                Advisors"
        src={AdvisorImg}
        buttoncont2="Register"
        href2=  "/auth/3/signup"
        // exploreButton={exploreButton}
        // href3="/ComingSoon"
        description1="Agronomist | Vet | Certification agencies "
        onClick={() => {
          localStorage.setItem("userType", "MSME-ADVISOR")
        }}
      /> */}
      {exploreButton && (
        <>
          {/* <AdvisaryComponent /> */}
          <h3 class="container-title text-center subSectionWrapper pb-0">Finance</h3>
        </>
      )}
      <HomeSubSection
        id="FEN-Investor"
        className3="image2"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
                Agri Investors"
        description="Agri Investors are key partners for FEN, investing in both Core Agribusiness and Micro-entrepreneurs. Their financial support drives growth and sustainability in the agricultural sector. Focusing on key activities and empowering Micro-entrepreneurs, Agri Investors contribute to a diverse and impactful investment portfolio, enhancing FEN's success and benefiting the broader agricultural community.
        "
        src={InvestorImg}
        buttoncont1="Register"
        href1="auth/3/signup"
        exploreButton={exploreButton}
    
       onClick={() => {
          localStorage.setItem("userType", "AssetOEM");
        }} 
        description1="Banks | NBFCs | Angel Investors | Institutional Investors "
      />
      {exploreButton && (
        <>
          <AgriInvestor />
        </>
      )}
      {/* 
                <h3 class="container-title text-center subSectionWrapper pb-0">Advertisement</h3>

      <HomeSubSection
        id="FEN-Advertisement"
        className3="image3"
        className="rowReverseAbout--mod borderedCards"
        description="Agri-Input Manufacturers are crucial partners for FEN, supplying essential inputs like animal feed, supplements, and healthcare products. This collaboration ensures a reliable source of quality inputs, supporting FEN in maintaining optimal animal health and well-being. The partnership underscores the importance of a strong relationship with input manufacturers for the overall success of livestock farming.
                "
        descriptionTitle="
                Agri-Input Manufacturers"
        description1="FMC | PMC | AHSP | AIC"
        src={MachineryImg}
        href1="auth/3/signup"
        buttoncont1="Register"
        
        exploreButton={exploreButton}
      
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      /> */}

      {exploreButton && (
        <>
          {" "}
          {/* <Advertisement /> */}

          {/* <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards"
            className3="image19"
            descriptionTitle="
            Machinery Providers"
            description="
            FEN is your trusted source for a diverse range of agricultural machinery. Whether you're a farmer or an farmer franchise, we supply the equipment you need to optimize your operations. Count on us to provide reliable machinery that supports your farming goals and drives efficiency.
            "

            href1="auth/3/signup"
            buttoncont1="Register"
            
            exploreButton={exploreButton}
          
            onClick={() => {
              localStorage.setItem("userType", "PMC");
            }}
        
          />
          <AdvertisementCBN /> */}

          <h3 class="container-title text-center subSectionWrapper pb-0">Tenders</h3>
        </>
      )}

      <HomeSubSection
        id="FEN-Tenders"
        className3="image4"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        Buyers"
        description="Buyers are integral partners for FEN, forming a crucial link in the agricultural supply chain. These partners play a vital role in the success of FEN by purchasing the produced crops and livestock. The collaboration with buyers ensures a market for the farm's products, contributing to financial sustainability and fostering a mutually beneficial relationship. This partnership highlights the interconnected nature of the agricultural ecosystem, where FEN and buyers work together to meet market demands and achieve overall success."
        // description="Through FEN distributor marketplace portal allows the corporate buyers to act as local distributor and exporters . Through this portal, corporate buyers keep track of transactions, make online orders and track the orders . The benefits for corporate buyers is to get quantity, quality and availability market fit assets for processing that have a direct impact on operations of downstream food supplier companies."
        // description1="Forecasting | Quality Certified| Reliable Supply | Fair Price | Improved Operational Efficency"
        src={ExporterImg}
        href1="auth/3/signup"
        buttoncont1="Register"
        // href1="auth/21/signin"
        // buttoncont1={`${exploreButton ? "Explore Exporters" : "Sign In"} `}
        // exploreButton={exploreButton}
      
        onClick={() => {
          localStorage.setItem("userType", "AssetDistributor");
        }}
        description1="Corporate Buyers | Merchant Exporters | Distributors | Wholesalers | Brands "
      />
      {exploreButton && (
        <>
          <TendersComponent />
          <h3 class="container-title text-center subSectionWrapper pb-0">Transporters</h3>
        </>
      )}
      <HomeSubSection
        id="FEN-Transporters"
        className3="image5"
        className="rowReverseAbout--mod borderedCards"
        description="FEN collaborates seamlessly with Logistic Providers through the FEN Logistics Feature. This innovative platform empowers logistics companies and their teams to efficiently manage their entire fleet, including booking details, trip scheduling, and load coordination. FEN Platform facilitates smooth interactions between logistics providers and their drivers, whether in-house or third-party, streamlining the transportation process and enhancing overall operational efficiency within the agricultural supply chain.
        "
        // description="FEN Platform provides logistics faeture. With FEN Platform, logistics companies and their staff can manage their entire fleet, booking details, trips scheduling, scheduling loads & orders to their own drivers or 3rd party drivers."
        descriptionTitle=" Logistic Providers"
        src={MSMEImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/13/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore  Logistic Providers" : "Sign In"
        // } `}
       
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "LogisticProvider");
        }}
        description1= "Global Logistic Providers | Hyperlocal Transporters | Cold Chain Logistics Providers "
      />

      {exploreButton && (
        <>
          {" "}
          <Transporters />
          {/* <h3 class="container-title text-center subSectionWrapper pb-0">Govt Schemes</h3> */}
        </>
      )}

      {/* <HomeSubSection
        id="FEN-Govt"
        className3="image6"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        Government Organizations"
        description="FEN provides open platform for collaboration and engagemnet with farmer associations, local governments and private organizations in the farming sector to promote awareness of the financial and required services across India. "
        description1="Local Goverments | Central Goverments "
        src={GovernmentOrganizationImg}
        href1="auth/3/signup"
        buttoncont1="Register"
        // href1="auth/8/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Government Organizations" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/agency"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "GovtDept");
        }}
      /> */}
      {exploreButton && !extraData && (
        <>
          {/* <GovtSchemesComponentABN /> */}
          <h3 class="container-title text-center subSectionWrapper pb-0">Farm Updates</h3>
        </>
      )}

      {exploreButton && !extraData && (
        <>
          <HomeSubSection
            className3="image55"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Producers"
            description="FEN provides open platform for collaboration and engagemnet with farmer associations, local governments and private organizations in the farming sector to promote awareness of the financial and required services across India. "
            description1="Local Goverments | Central Goverments "
            src={GovernmentOrganizationImg}
            href1="auth/3/signup"
            buttoncont1="Register"
            // href1="auth/3/signin"
            // buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}

            onClick={() => {
              localStorage.setItem("userType", "GovtDept");
            }}
          />
          <SiteUpdates />
        </>
      )}

{exploreButton && !extraData && (<>

<h3 class="container-title text-center">Buy Inputs</h3>

<HomeSubSection
  className="rowReverseAbout borderedCards borderedCards"
  className3="image20"
  description="we collaborate with leading nutrition companies that specialize in providing essential dietary solutions for animals and farms. This partnership ensures that agri-producers have access to expert guidance and high-quality nutrition options to enhance the health and productivity of their animals and overall agricultural operations."
  descriptionTitle="
  Nutrition Companies"
  onClick={() => {
    localStorage.setItem("userType", "AgroDealer");
  }}
  exploreButton={exploreButton}

  href1="auth/3/signup"
  buttoncont1="Register"
/>

<HomeSubSection
  className="rowReverseAbout--mod borderedCards borderedCards"
  className3="image24"
  description="At FEN, we've built a supportive network where renowned agri-input companies come together to provide essential solutions for farming animals and fields. This collaborative effort ensures agri-producers receive expert guidance and high-quality agri-input options, boosting the health, productivity, and sustainability of their farms.
  "
  descriptionTitle="
  Agri Input Companies"
  onClick={() => {
    localStorage.setItem("userType", "AgroDealer");
  }}
  exploreButton={exploreButton}

  href1="auth/3/signup"
  buttoncont1="Register"
/>

<BuyInput />




{/* <h3 class="container-title text-center">Machinery</h3>

<HomeSubSection
  className="rowReverseAbout borderedCards borderedCards"
  className3="image28"
  description="Within the vibrant FEN community, we join forces with renowned agri machinery providers to deliver essential solutions tailored for farms. This cooperative effort ensures agri-producers benefit from expert guidance and superior agri machinery options, ultimately enhancing the efficiency, productivity, and sustainability of their farming operations."
  descriptionTitle="
Agri Machinery"
href1="auth/3/signup"
buttoncont1="Register"
/>

<HomeSubSection
  className="rowReverseAbout--mod borderedCards borderedCards"
  className3="image29"
  description="Within the dynamic FEN community, we partner with leading processing machinery providers to offer essential solutions customized for farms. This collaborative effort ensures agri-producers gain access to expert guidance and high-quality processing machinery options, resulting in improved efficiency, productivity, and sustainability for their farming operations."
  descriptionTitle="
Processing Machinery"
href1="auth/3/signup"
buttoncont1="Register"
/>

<Container className="mt-4">
  <ProcessedProducts
    type="MACHINERY"
    userType={["PMC", "FMC"]}
    cbn="true"

  />
</Container> */}


{/* <div className="mb-lg-5 mb-4">
            <JobsComponent />
          </div>
          <div className="mb-lg-5 mb-4">
            <PriceDiscovery />
          </div>
          <div className="mb-lg-5 mb-4">
            <ClubUpdates />
          </div> */}

          {/* <div className="mb-lg-5 mb-4">
            <SiteWeatherManagement />
          </div> */}

          {/* <div id="tendersContainer" className="container-fluid">
            <h3 id="AgriAssets" className="container-title">
              {" "}
              Agri Assets
            </h3>
            <Container>
              <ProcessedProducts
                type="AGRI-ASSETS"
                userType={["FOOD-PROCESSOR"]}
                cbn="true"
              />



            </Container>
          </div> */}


          <h3 class="container-title text-center">Storage</h3>

          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards"
            className3="image23"
            descriptionTitle="
            Storage Providers"
            description="Partnering with established storage providers, we offer essential storage solutions for agricultural produce and products. This collaboration ensures agri-producers have reliable access to storage facilities, facilitating the secure preservation and effective management of their goods."
            href1="auth/3/signup"
            buttoncont1="Register"
            userType="Storage Providers"
          />
          <StorageProvider />


{/* 
          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards"
            description="Teaming up with proficient transporters, we facilitate the seamless movement of agricultural produce. This partnership ensures agri-producers have access to efficient transportation solutions, enabling the smooth and timely delivery of their goods to the intended destinations."
            descriptionTitle="
            Transporters"
            src={TransporterImg}
            href1="auth/3/signup"
buttoncont1="Register"
            userType="Transporters"
          /> */}

          {/* <h3 class="container-title text-center">Contaract Maufacturers</h3> */}

          {/* <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards borderedCards"
            className3="image33"
            descriptionTitle="Contract Manufacturers"
            description="Partnering with established storage providers, we offer essential storage solutions for agricultural produce and products. This collaboration ensures agri-producers have reliable access to storage facilities, facilitating the secure preservation and effective management of their goods."
            href1="auth/3/signup"
            buttoncont1="Register"
            userType="Storage Providers"
          /> */}




</>)}
{extraData && <>
{/* <HomeSubSection
            className3="image55"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Farmers"
            description="Farmers are vital partners for FEN, collaborating to cultivate crops and raise livestock sustainably. This partnership emphasizes mutual support, with FEN providing resources and expertise, and farmers contributing dedication for a successful harvest. Together, they advance agricultural practices and promote food security.
            "
            description1="Crop Farmers | Livestock Farmers | Organic Farmers | Diversified Farmers"
            src={GovernmentOrganizationImg}
            href2="auth/3/signup"
            buttoncont2="Register"
            href1="auth/3/signin"
            buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}
            href3="/agri-producers"
            buttoncont3="Explore More"
          /> */}


          <HomeSubSection
            className3="insurance-company-bg"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Insurance Company"
            description="Insurance Company is a valued partner of FEN, providing essential coverage for our crops, livestock, and agricultural assets. This collaboration enhances our resilience and ensures the sustainability of our operations, allowing us to navigate uncertainties with confidence. Together, we are committed to fostering a secure and thriving environment for sustainable farming practices            . "
            description1="Agri-Asset Insurer | Supply Chain Distribution Insurer"
            src={GovernmentOrganizationImg}
            href1="auth/3/signup"
            buttoncont1="Register"
            // href1="auth/3/signin"
            // buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            // exploreButton={exploreButton}
            // href3="/ComingSoon"
            // buttoncont3="Explore More"
          />

<HomeSubSection
            className3="technology-provider-image"
            className="rowReverseAbout borderedCards pb-0"
            descriptionTitle="
        Technology Providers"
            description="FEN is proud to partner with leading technology providers to advance our agricultural practices. This collaboration integrates state-of-the-art technologies into our farming operations, enhancing efficiency, precision, and overall productivity. The expertise and innovative solutions provided by our technology partners contribute significantly to our success. Together, FEN and our technology partners are dedicated to leveraging the latest advancements to ensure sustainable and cutting-edge practices in modern agriculture.
            "
            description1="IoT Solution Providers | CRM & ERP Providers | Irrigation Providers | Satellite Imagery provider | Device Makers"
            href1="auth/3/signup"
            buttoncont1="Register"
          />
          {/* <SiteUpdates />
        </> */}
    









<HomeSubSection
        id="FEN-Govt"
        className3="ari-bg"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
         Agriculture Research Institutions"
        description="FEN is pleased to collaborate with Agriculture Research Institutions as a valuable partner. This partnership enhances our farming practices by integrating cutting-edge research and innovations. The expertise and resources from Agriculture Research Institutions contribute to the continual improvement of our agricultural methods, fostering sustainable and efficient farming practices at FEN. Together, we strive to achieve excellence in agriculture through the synergy of practical experience and scientific advancements. "
        description1="Local Goverment Institutions | Central Goverment Institutions | Private Institutions "
        src={GovernmentOrganizationImg}
        href1="auth/3/signup"
        buttoncont1="Register"
        // href1="auth/8/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Government Organizations" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/ComingSoon"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("abnUser", "ARI");
        }}
      />
      
      <HomeSubSection
        id="FEN-Govt"
        className3="forManufactures"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
         Brands"
        description="Food brands partnering with the FEN Network leverage the platform's global reach and expertise to enhance their visibility, distribution channels, and market presence. Through this collaboration, food brands gain access to a vast network of suppliers, retailers, and consumers, enabling them to expand their market reach and foster brand recognition on an international scale. By aligning with the FEN Network's values of quality, sustainability, and innovation, these food brands can leverage strategic partnerships and resources to drive growth, enhance product offerings, and create value for both their business and consumers. "
        // description1="Local Goverment Institutions | Central Goverment Institutions | Private Institutions "
        src={GovernmentOrganizationImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/8/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Government Organizations" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/ForBrands"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("abnUser", "FOOD-PROCESSOR");
        }}
      />



      
      </>}
    </div>


  );
}
