import React from "react";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon10 from "../../images/global-market.svg";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import Icon1 from "../../images/CustomerBenefit/productivity.svg"
import Icon2 from "../../images/CustomerBenefit/reputation-management.svg"


export default function AdvisorsFeatures() {
  const features = [
   
    {
      image: Icon1,
       title: "Collaboration and Networking",
       bodycopy:
         "The FEN Network provides advisors with the Collaboration and Networking feature, enabling connections with other advisors, access to farmer groups, MSMES and forums, and participation in networking events and webinars. "
     },
 {
       image: Icon2,
       title: "Government Schemes Integration ",
       bodycopy:
         "The Government Schemes Integration feature provides advisors with information on farmer and MSME government schemes, assistance in applying for grants, updates on government programs, and case management for tracking applications."
     },
     
     {
        image: Icon2,
        title: "Advertisement Management ",
        bodycopy:
          "The Advertisement Management feature offers advisors opportunities to advertise services within the platform for farmers and MSMEs, analytics on advertisement performance, customizable ad options (targeted ads, sponsored content), and integration with social media for wider reach."
      },

      {
        image: Icon2,
        title: "Advisory Management ",
        bodycopy:
          "The Advisory Management feature enables advisors to easily connect with farmers and MSMEs, facilitating timely and relevant consultations."
      },
  ];
  return (
    <>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Services</h2>
      <Row>
        {features.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </>

  );
}
