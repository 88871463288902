import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/white-label1.jpg";
import PrivateLabelling from "../../images/brand-images/private-labelling.jpg";
import Supplier from "../../images/brand-images/working-warehouse-loaders-moves-boxes-industrial-min.jpg";
import Market from "../../images/brand-images/global-online-shopping-concept-laptop-keyboard-with-shopping-basket-3d-rendering-min.jpg";
import NewDevelopment from "../../images/brand-images/skills-concept-skills-education-learning-personal-development-competency-business-min.jpg";
import BrandCTA from "../BrandCTA/BrandCTA";
export default function AgriFoodManufactureMoD(){
    return(
        <>
        <HeaderComponent/>
        <UpdatesBanner 
         className="MoDBanner text-white"
         bannerText="Manufacturing on Demand"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
      <div className="for-manufacturers-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          description="Agrifood manufacturers specializes in providing contract manufacturing services, catering to diverse product needs. With a proven track record of expertise and reliability, they collaborate closely with food brands to bring their concepts to life. From formulation to production and packaging, Agrifood manufacturer ensures a seamless manufacturing process, delivering high-quality products that meet client specifications through decentralized processing model."
          title="Contract Manufacturing"
          src={ContractFarmingImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="Agrifood manufacturers excels in private labeling services, allowing food businesses to brand high-quality products as their own. Through this service, brands can leverage food processing enterprise's expertise to bring their unique products to market with a personalized touch.
          "
          title="Private Labelling"
          src={PrivateLabelling}
        />
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="GBR serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. brands benefit from GBR's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        /> */}
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout"
          description="Agrifood manufacturers offers new product development services tailored to brands, helping them innovate and diversify their product offerings to meet evolving market demands."
          title="New Product Development"
          src={NewDevelopment}
        />
        <BrandCTA/>
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="GBR provides brands with the service of expanding their product sales into both local and global marketplaces, leveraging their expertise for effective market penetration.
          "
          title="onMarketplace"
          src={Market}
        /> */}
</div>

        <FooterComponent/>
        </>
    )
}