import React from "react";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import FarmersNeed from "../Farmer/FarmersNeeds";
import FarmersServices from "../Farmer/FarmersServices";
import "./CS.css"
import CSNeeds from "./CSNeeds";

export default function Farmers() {
  return (
    <>
      <HeaderComponent />
        <UpdatesBanner className="ap-cs" bannerText="CS" />
        <CSNeeds/>
        <FarmersServices/>


      <FooterComponent />
    </>
  );
}
