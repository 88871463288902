import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function FarmMachineryMarketplaceNavbar() {
    const navData = [
    {to:"/farm-machinery-marketplace", link:"For Farm Enterprise"},
    {to:"/farm-machinery-marketplace-for-farm-machinery-providers", link:"For Farm machinery providers"},
  ]
//   const navData2 = [{to:"/AgriBusiness", link:"Agri-Business"},
//   {to:"/Brands", link:"Brands"},
// ]
  return (
    <><SecondaryNavbar props={navData}  /></>
  )
}
