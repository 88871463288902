import React from 'react'
import AboutList from './AboutList'

export default function ProductionService() {
    const data=[
  "onMarketplace:  onMarketplace is a marketplace provided by FEN Network for buying and selling agricultural products to a wider consumer."  ,
"Social CFonMarketplace:   Social CFonMarketplace, provided by the FEN network, is a platform where brands and food processing enterprises can place contract farming orders with farm enterprises.",
"AgroCare Marketplace:  Agrocare Marketplace, provided by FEN Network, offers a range of animal and plant care products, catering to the needs of agricultural businesses and enthusiasts.",
"Social iaaMarketplace: Social iaaMarketplace, provided by FEN Network, is a platform designed for farm enterprisees and investors to interact, collaborate, and conduct transactions within the agricultural sector.",
"BulkonMarketplace: BulkOnMarketplace, provided by the FEN network, is a B2B platform where buyers can purchase raw materials in bulk, and farm enterprises can sell their produce at scale.",
"Asset Marketplace: AssetMarketplace, offered by the FEN network, is a platform where farm enterprises can purchase agricultural assets, and sellers can list their products.",

   
    ]
  return (
   <AboutList title="Marketplace" contentlist={data}/>
  )
}