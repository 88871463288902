import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container } from "@material-ui/core";
import AgriFoodManufacturersBenefits from "./AgriFoodManufacturersBenefits";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgriFoodmanufacturersNeeds from "./AgriFoodmanufacturersNeeds";
import AgriFoodManufacturersJourney from "./AgriFoodManufacturersJourney";
import AgriFoodManufacturerersServices from "./AgriFoodManufacturerersServices";
import BrandPricingData from "../pricingTabs/BrandPricingData";
import CTA from "../CTA/CTA";
import TitleDescription from "../TitleDescription/TitleDescription";
import AgriEntreprenurBenefits from "./AgriEntreprenurBenefits";
import ContractManufactureTypes from "../ContractManufactureTypes/ContractManufactureTypes";
import AgriFoodManufacturerCustomerBenefit from "./AgriFoodManufacturerCustomerBenefit";
import AgriFoodManufacturerContribution from "./AgriFoodManufacturerContribution";
import FEServices from "./FEServices";
import Collections from "../Collections/Collections";
export default function ForAgriFoodManufacturers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
      


        bannerText={[
          "Want to be part of Farm Enterprise Network to source ",  <br className="d-lg-block d-none"></br>, "high-quality, authentic, and traceable agricultural raw products ",
          <br className="d-lg-block d-none"></br>,
          "  directly from verified Agri-Suppliers ",
        ]}
        bannerbodycopy="End to End Traceability | Food Safety | Assured Quality | Supply chain efficiency | Best Pricing | Global Supplier Discovery | Contract Farming"
      />

      <div className="main-wrapper2">
        <Container>
        <AgriFoodManufacturerersServices />
        </Container>
      </div>
        <Container>
        <FEServices />
        </Container>
        {/* <div className="main-wrapper2">

      <Collections title="Current Agricultural Asset Supported"/>
</div> */}
      <div className="MSMEBenfitJourney main-wrapper2 ">
        <Container>
        <AgriFoodManufacturersBenefits />
        </Container>
      </div>

  
      <FooterComponent />
    </>
  );
}
