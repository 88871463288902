import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./OnMarketplaceSell.css";
import MarketPlaceQuote from "../MarketPlaceQuote/MarketPlaceQuote";
import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";

import { useLocation, useHistory } from "react-router-dom";

export default function OnMarketplaceSell(){
  const location = useLocation();
  const history = useHistory();
  const customPropValue = location.state?.customProp;
  let navData = [
    { to: "/onMarketplace", link: "For B2C Buyers",  },
   { to: "/onMarketplaceSell", link: "For Sellers" }
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

  // Conditionally include customPropValue in navData
  if (customPropValue) {
    navData = [
      { to: "/onMarketplace", link: "For Buyers",  },
      // { to: "/onMarketplace-b2b-buyers", link: "For B2B Buyers",  },    
      { to: "/onMarketplaceSell", link: "For Farm Enterprise" }
      // { to: "/onMarketplaceRFQ", link: "RFQ" }
    ];
  }

    return(
        <>
        <HeaderComponent className="noShadow"/>
        <SecondaryNavbar props={navData}/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling organic raw products directly to consumers and other buyers"
         buttonText="Register Now"
         href="auth/3/signup"
         clicked={() => {
          localStorage.setItem("userType", "AssetDeveloper");
        }}        />
        {/* <MarketPlaceQuotePP/> */}
        <FooterComponent/>
        </>
    )
}