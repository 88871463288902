import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./FooterComponent.css";
import { Fade } from "react-reveal";
import { NavLink } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import FooterCopyright from "./FooterCopyright";

export default class FooterComponent extends React.Component {
  render() {
    return (
      <>
        <div className="footerContainer">
          <Grid container spacing={1} className="footerRow">
            <Grid item md={2} sm={4} xs={6} className="footerCol">
              <h5>Our Story</h5>
              <ul>
                <li>
                  <NavLink to="/About">About Us</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/ExploreInvestment">For Agri-Investors</NavLink>
                </li>*/}
               
                <li>
                <NavLink to="/partners">Our Partners</NavLink>
              </li>
              <li>
                <NavLink to="/ExploreTechnology">Our Technology</NavLink>
              </li>
                {/* <li>
                <NavLink to="/">For Farmer Franchise</NavLink>
              </li> */}
 {/* <li>
                  <NavHashLink to="/ExploreNetwork">
                  Explore Network
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink to="/ExploreTechnology">
                  Explore Apps{" "}
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink to="/ExploreAPIs">
                  Explore APIs
                  </NavHashLink>
                </li> */}
              </ul>
            </Grid>
            <Grid item md={2} sm={4} xs={6} className="footerCol">
              <h5> Our Services</h5>
              <ul>
                {/* FOR MAIN WEBSITE Farm Enterprise will be sub page in other case farm enterprise will be home so comment this and uncomment second one */}
                {/* <li>
                  <NavLink to="/farm-enterprise">For Farm Enterprise</NavLink>
                </li> */}
                {/* <li>
                  <NavLink exact = "true" to="/">For Farm Enterprise</NavLink>
                </li>
            */}
                {/* <li>
                  <NavLink to="/agro-dealers">For Agro-Dealers</NavLink>
                </li>
                
              
                <li>
                  <NavLink to="/agri-food-manufacturers" >For Agrifood Manufacturers</NavLink>
                </li> */}
       
                <li>
                  <NavLink to="/">For Farm Enterprise</NavLink>
                </li>
                <li>
                  <NavLink to="/agriminer" >For Agri Industry Advisors</NavLink>
                </li> 
                <li>
                  <NavLink to="/food-processing-enterprises">For Food Processing Enterprise</NavLink>
                </li>
                {/* <li>
                  <a href="https://growlivestock.com/"  target="_blank" rel="noopener noreferrer"> For Agri-Entrepreneurs
 </a>
                </li> */}
                
              </ul>
            </Grid>
            <Grid item md={2} sm={4} xs={6} className="footerCol">
              <h5>Quick Access</h5>
              <ul>
                {/* <li>
                  <NavLink to="/onMarketplace">onMarketplace</NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/d2c-onmarketplace">D2ConMarketplace</NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/bulk-onmarketplace">BulkonMarketplace</NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/b2b-onmarketplace">B2BonMarketplace</NavLink>
                </li> */}
                <li>
                  <NavLink to="/social-cf-onmarketplace">
                    Social  CFonMarketplace
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/agri-input-marketplace">AgriInputMarketplace</NavLink>
                </li>
                <li>
                  <NavLink to="/bulk-onmarketplace">AgriOutputMarketplace</NavLink>
                </li>

             
{/* 
                <li>
                  <NavLink to="/socialCMonMarketplace">
                    Social CMonMarketplace
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/socialCFonMarketplace">
                    Social CFonMarketplace
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink to="/ExploreAnimalCare">Explore Animal Care</NavLink>
                </li>
                <li>
                  <NavLink to="/ExplorePlantCare">Explore Plant Care</NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/agrocare-marketplace">AgroCare Marketplace</NavLink>
                </li> */}
                <li>
                  <NavLink to="/iaaMarketplace">Social iaaMarketplace</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/asset-marketplace">
                     Asset Marketplace
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="auth/8/signin">Agent </NavLink>
                </li> */}
                  {/* <li>
                  <NavLink to="/community">Community</NavLink>
                </li> */}
              </ul>
            </Grid>

            <Grid item md={2} sm={4} xs={6} className="footerCol">
              <h5>Quick Links</h5>
              <ul>
                {/* <li>Insights</li> */}

                {/* <li>
                  <NavLink to="/EngagementServices">
                    Explore Engagement Services
                  </NavLink>
                </li> */}

                {/* <li>
                <NavLink
                  rel="noopener noreferrer"
                  className="tendersLink"
                  to="/ExploreTechnology"
                >
                  Explore Technology
                </NavLink>
              </li> */}
                {/* <li>
                <NavLink to="/ExploreApps">Explore Apps</NavLink>
              </li> */}

                {/* <li> 
                <a rel="noopener noreferrer" className="tendersLink" href="http://13.127.248.81:3720/">
                  GBR Platform
                </a>
              </li> */}

                {/* <li>
                <NavLink
                  rel="noopener noreferrer"
                  className="tendersLink"
                  to="/MSMEBenefitsJourney"
                >
                  MSME Benefits & Journey
                </NavLink>
              </li> */}
                {/* <li><a href="/Producer">Become a Producer</a></li>
              <li><a href="/becomeBuyer">Become a Buyer</a></li>
              <li><a href="/businesspartner">Partners</a></li>
              <li><a href="/Investments">Investments</a></li> */}
                {/*  <li>Policies & Procedures</li> */}
                {/* <li>
                  <NavLink to="/">Home</NavLink>
                </li> */}
                <li>
                  <NavLink to="/Agent">Careers</NavLink>
                </li>
                <li>
                  <NavLink to="/Terms&Conditions">T&C</NavLink>
                </li>
                <li>
                  <NavLink to="/PrivacyPolicy">Privacy Policy</NavLink>
                </li>
                {/* <li>
                <a href="/Community">Community</a>
              </li> */}

                {/* <li><a href="/terms">Disclaimer</a></li> */}
                {/* <li>
                <a
                  rel="noopener noreferrer"
                  className="tendersLink"
                  target="blank"
                  href="http://13.127.248.81:3700/"
                >
                  Tenders
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  className="tendersLink"
                  target="blank"
                  href="http://localhost:3002/"
                >
                  Playground Platform 
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" className="tendersLink" href="http://13.127.248.81:3710/">
                  GBR Ventures Platform
                </a>
              </li>*/}
              </ul>
            </Grid>
            <Grid item md={2} sm={4} xs={6} className="footerCol">
              <h5>Contact Us</h5>
              {/* <p className="gdpInfoText">
              Address: 49054, FLOOR-5, NA-WING-49, SOBHA DREAM ACRES, BALAGERE
              BANGALORE KA 560087 IN, <br />
              Phone No:<a href="tel:9845680035">+91 9845680035</a>,
              <br />
              E-mail: <a href="mailto:sales@gbrapp.com">sales@gbrapp.com</a>
            </p> */}

              <p className="gdpInfoText">
                <b>Address:</b>
                <br></br>
                Salarpuria Touchstone, <br></br>
                No. 133/1-3, First Floor, A Block, <br></br>
                Kadubeesanahalli,<br></br> Outer Ring Road,<br></br>
                Bangalore - 560103, India.
                {/* 49054, Floor-5, NA-Wing-49,
                Sobha Dream Acres,
                <br></br> Balagere Bangalore, <br></br> KA 560087 IN */}
                <span>
                  <b>Phone No:</b>
                  <br></br>
                  <a href="tel:9845680035">+91 9845680035</a>
                </span>
                <span>
                  <b>E-mail:</b> <br></br>{" "}
                  <a href="mailto:sales@gbrapp.com">sales@gbrapp.com</a>
                </span>
              </p>
            </Grid>

            <Grid
              item
              md={2}
              sm={4}
              xs={6}
              className="footerCol contactFormCnt"
            >
              <h5>STAY CONNECTED</h5>
              <div className="socialCnt">
                <a
                  href="https://www.facebook.com/GBR-Farming-107164845426710/?mibextid=RUbZ1f&ti=as"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg viewBox="0 0 24 24" title="Facebook">
                    <path
                      fill="#666666"
                      d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/gbr-farming/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="#666666"
                      d="M21,21H17V14.25C17,13.19 15.81,12.31 14.75,12.31C13.69,12.31 13,13.19 13,14.25V21H9V9H13V11C13.66,9.93 15.36,9.24 16.5,9.24C19,9.24 21,11.28 21,13.75V21M7,21H3V9H7V21M5,3A2,2 0 0,1 7,5A2,2 0 0,1 5,7A2,2 0 0,1 3,5A2,2 0 0,1 5,3Z"
                    />
                  </svg>
                </a>

                <a
                  href="https://instagram.com/gbrfarming?igshid=NmQ2ZmYxZjA="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="#666666"
                      d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                    />
                  </svg>
                </a>

                <a
                  href="https://twitter.com/gbrfarming?t=k264tpWjL5V6Ome7DLbhDg&s=08"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="#666666"
                      d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
                    />
                  </svg>
                </a>

                <a
                  href="https://www.youtube.com/@gbrfarming"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#666666"
                    class="bi bi-youtube"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />{" "}
                  </svg>{" "}
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
        <FooterCopyright />
      </>
    );
  }
}
