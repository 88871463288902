import React,{useState} from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
// import "./ContractManufacturing.css"
import ProcessedProducts from '../ExploreProducts/ProcessedProducts';
import { Container } from '@material-ui/core';
import FarmMachineryMarketplaceNavbar from './FarmMachineryMarketplaceNavbar';

export default function FarmMachineryMarketplace() {
    const [userType, setUserType] = useState(["FOOD-PROCESSOR"])

  return (
    <>
    <HeaderComponent className="noShadow"/>
    <FarmMachineryMarketplaceNavbar/>

    <UpdatesBanner 
         className="socialMarketplace text-white"
         bannerText={["FarmMachinery Marketplace"]}
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
   <div className="explore-product-wrapper">
        <Container>
          <div className=" ">
            {/* Raw-Products */}
       <div id="tendersContainer" className="container-fluid">
              <h3 id="Raw-Products" className="container-title  ">
                Farming Machinery
              </h3>
              <ProcessedProducts  quote="true"  type="FARM-MACHINERY" userType = {userType} />
            </div>
            {/* Semi-Processed-Products */}
           
          </div>
        </Container>
      </div>
      {/* <div className="subSectionWrapper">
      <h2 className='container-title mb-md-5 text-center'>Farm Enterprises</h2>

        <ClubMainPage type={["AssetDeveloper"]}  rfq = "true"/>
      </div> */}
           <FooterComponent/>
    </>
  )
}
