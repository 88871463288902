import React from 'react'
import AboutList from './AboutList'

export default function Network() {
    const data=[
// "DTN (Digital Transformation Network):   The Digital Transformation Network is a network provided by FEN Network to farm enterprise for managing Agri-MSMEs and facilitating their digital transformation journey within the agricultural sector.",
"AAMC (Agri Asset Management Network): The Agri Asset Management Network is a sub-network provided by the FEN Network that allows farm enterprises to effectively manage and coordinate AAMC members and associated farms within their sub-network. It supports streamlined asset management and operational efficiency.",
"DSN (Dark Store Network): The Dark Store Network is a sub-network offered by the FEN Network to assist farm enterprises in managing agricultural products. It also facilitates their digital transformation within the agri-food sector, helping to modernize inventory management and logistics.",
"Global Network: The Global Network is a platform provided by FEN that enables farm enterprises to connect and collaborate with global partners, including vendors and customers, fostering international interaction, partnerships, and market access.",
"CBN (Community Based Network):  The Community Based Network is a platform offered by the FEN Network to support farm enterprises in managing and coordinating their network of agri-producers. It strengthens local collaboration and facilitates resource-sharing within agricultural communities.",

       
    ]
  return (
   <AboutList title="Network" contentlist={data}/>
  )
}