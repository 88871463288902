import React from 'react';
import "./GBRNetworkBusiness.css"
import { NavLink } from 'react-router-dom';

export default function GBRNetworkBusinessCards({purpose, title, subtitle, bodycopy, classname, redirect, href}) {
  return (
    <div className={`business-cards ${classname} rounded-4 mb-3 d-block`}><p>{purpose}</p> <h1 className='mb-3'>{title}</h1> <h2 cl
    mb-3>{subtitle}</h2> <p className='mb-3'>{bodycopy}</p> 
    
    {redirect === "web" ? <NavLink className='d-inline-block  text-white text-decoration-none'  to={href}>Explore</NavLink> : <a className='d-inline-block text-white text-decoration-none' href={href} target='_blank' rel="noopener noreferrer">Explore</a> }
    </div>
  )
}
