import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./MarketPlaceQuote.css";

const MarketPlaceQuotePP = () => {
  const [quoteDetails, setQuoteDetails] = useState([]);
  const accessToken = localStorage.getItem("GlobalAccess");

  useEffect(() => {
    fetchQuotes();
  }, []);

  const fetchQuotes = () => {
    fetch(
      "https://order.gbrapp.com/tradeHubProxy/get-all-request-ids?network-id=consumer2&request-type=agri-buy-products-rfq-req&product-category=PROCESSED-PRODUCTS&product-sub-category=&product-model-id=&rfq-status=&page-no=0&page-size=6&sort-by=id&sort-dir=asc",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "network-id": "consumer2",
          "parent-id": "consumer2",
          Authorization: "Bearer " + accessToken,
          username: "service-account-gbrapp",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const quoteIds = data.map((item) => item.rfqId);
        const requests = quoteIds.map((rfqId) => {
          const detailsUrl = `https://order.gbrapp.com/tradeHubProxy/get-request-details?id=${rfqId}`;
          return fetch(detailsUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "network-id": "consumer2",
              "parent-id": "consumer2",
              Authorization: "Bearer " + accessToken,
              username: "service-account-gbrapp",
            },
          }).then((res) => res.json());
        });

        return Promise.all(requests);
      })
      .then((details) => {
        console.log(details, "Details for each quoteId");
        setQuoteDetails(details);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <div className="explore-product-wrapper subSectionWrapper" id="RFQ">
        <h2 className="container-title text-center">Quotes</h2>
        <Container>
          <Row>
            {quoteDetails && quoteDetails.length !== 0 ? (
              quoteDetails.map((e) => (
                e.requestDetails.productDetails.map((product, index) => (
                  <Col md={4} key={index}>
                    <div className="quote-card">
                      <p>Category: {product.productCategory}</p>
                      <p>Sub-category: {product.productSubCategory}</p>
                      <p>Origin: {product.origin}</p>
                      <p>Quantity: {product.quantity} {product.unit}</p>
                      <NavLink
                        className="text-white text-decoration-none d-block"
                        to="/auth/3/signup"
                      >
                        <button
                          type="button"
                          className="btn btn-success mx-lg-0 mx-auto"
                        >
                          Create Quote
                        </button>
                      </NavLink>
                    </div>
                  </Col>
                ))
              ))
            ) : (
              <div className="card">
                <p className="card-body container-heading2">
                  <b>No Quotes Found</b>
                </p>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MarketPlaceQuotePP;
