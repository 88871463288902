import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import ClubMainPage from "../Club/ClubMainPage";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default function AgriBusinessCompany() {
  const userTypes = ["AssetDeveloper"];

  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="socialMarketplace text-white"
        bannerText="Agri Asset Management Company"
      />
      <div className="subSectionWrapper">
        <ClubMainPage type={userTypes} />
      </div>{" "}
      <FooterComponent />
    </>
  );
}
