import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon6 from "../../images/gbrFarmerBenefits/breakdown.svg";
// import "./ForFarmerSection3.css"
 
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
 
export default function CBOBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Local & Global Trade ",
      bodycopy:
        "CBOs excel in local and global trade by leveraging local resources and networks for sustainable economic growth and market integration.",
    },
    {
      image: Icon5,
      title: "Empowering Agri-Producers ",
      bodycopy:
        "Empowering agri-producers to access and apply for government schemes, CBOs play a pivotal role in enhancing awareness, driving participation, and ultimately contributing to the socio-economic upliftment of the community.",
    },
    {
      image: Icon6,
      title: "Build & manage own community ",
      bodycopy:
        "CBOs experience a notable advantage by establishing, overseeing, and operating a network of agri-producers, promoting enhanced coordination, resource management, and agricultural progress within the community."
    },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">CBO Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
   
    </div>
    </Container>
 
  );
}