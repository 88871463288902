import React from "react";
import OurPartners from "../OurPartners/OurPartners";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
export default function CommunityPartners() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="partnerbg" bannerText={["Partners who helps Farm enterprise", <br className="d-lg-block"></br>, "and Agri-entrepreneurs"]}
        />

      {/* <NetworkBanner /> */}
      <div className="main-wrapper2">
        <OurPartners/>
      </div>
      <FooterComponent />
    </>
  );
}
