import React from "react";
import Icon1 from "../../../images/ServiceIcons/machinery.svg";

import Icon3 from "../../../images/ServiceIcons/advisor.svg";
import Icon6 from "../../../images/ServiceIcons/SellOnline.svg";
import Icon7 from "../../../images/ServiceIcons/Export.svg";
import AssetMonitoring from "../../../images/ServiceIcons/asset-monitoring.svg";
import PaperlessProcess from "../../../images/ServiceIcons/paperless-process.svg"
import PO from "../../../images/ServiceIcons/purchase-order.svg"
import Provenace from "../../../images/ServiceIcons/provenance.svg"
import ContractFarming from "../../../images/ServiceIcons/provenance.svg";

import OurServiceCardContainer from "../../OurServices/OurServiceCardContainer";

export default function AAMCNetworkService() {
  const PPNFeatures = [
   
    

    {
      image: PaperlessProcess,
      title: " Efficient Management of farming Plants ",
      bodycopy:
        "AAMC Network leverages modern technologies to create an interconnected and efficient ecosystem for managing farming plants in the agriculture industry. By integrating IoT, data analytics, automation, and stakeholder collaboration, the AAMC  optimizes farming processes, ensures product quality and safety, and promotes sustainability. ",
      // href:"#ABN-Tender"
    },

 
    {
      image: Provenace,
      title: "Local user management to manage orders",
      bodycopy:
        "Local User Management service streamlines order management within agri asset management company, enhancing efficiency and coordination. Gain real-time oversight and control of orders to optimize operations and boost productivity   ",
    },
    {
      image: Icon1,
      title: "Onboarding Service ",
      bodycopy:
        "AAMC network offers an onboarding service, empowering food processing enterprises to establish their own network of approved  processing entrepreneur through agents/ advisors, ensuring seamless integration with 3rd party processing infrastructure.",
      //href:"#ABN-Advertisement"
    },

    // {
    //   image: AssetMonitoring,
    //   title: "Plant Machinery Maintenance  Service ",
    //   bodycopy: "Plant Machinery Maintenance Service, offered by the Agri Asset Management Company (AAMC), ensures efficient upkeep and repair of machinery for food processing enterprises. This service helps minimize downtime and maintain optimal production performance.",
    //   // href:"ABN-Advisory"
    // },

    {
        image: AssetMonitoring,
        title: "Sustainability Framework ",
        bodycopy: "The Sustainability Framework, provided by the Agri Asset Management Company (AAMC), helps food processing enterprises implement eco-friendly practices. This service focuses on reducing environmental impact and promoting sustainable production processes.",
        // href:"ABN-Advisory"
      },

      {
        image: ContractFarming,
        title: "Agri Contract-Farming Services",
        bodycopy: " Agri Asset Management Company (AAMC) facilitates Agri Contract-Farming Services, bridging Agri-entrepreneurs with brands to streamline agricultural production, fostering mutually beneficial partnerships and sustainable growth in the agri-industry."    },
     
        {
            image: ContractFarming,
            title: "Investable Asset Management Service",
            bodycopy: " Agri Asset Management Company (AAMC) offers an Investable Asset Management Service, allowing investors to entrust farming assets to agri-entrepreneurs, fostering efficient utilization and maximizing returns in the agricultural sector."    },

            {
                image: ContractFarming,
              title: "Efficient Farm Managment Services",
              bodycopy: " Agri Asset Management Company (AAMC) offers efficient management of farms in agricultural production by  integrating  various technologies and stakeholders within network to optimize farming practices, enhance productivity, and ensure sustainable agricultural practices . By integrating IoT, data analytics, automation, and stakeholder collaboration, the Agri Asset Management Company (AAMC) transforms traditional farming into a modern, efficient, and sustainable practice. This approach not only addresses current agricultural challenges but also paves the way for future innovations in the industry."    },
  
  ];
  return <OurServiceCardContainer services={PPNFeatures} title="Services" />;
}
