import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ProcessingMachinery from "../../images/buy-assets-marketplace/processing-machinery.svg";
import FarmingMachinery from "../../images/buy-assets-marketplace/farming-machinery.svg";
import AnimalImg from "../../images/buy-assets-marketplace/honeycomb.svg";
import FeedProcessingMachinery from "../../images/buy-assets-marketplace/feed-processing-machinery.svg";

import AgriAssets from "../../images/buy-assets-marketplace/agri-asset.svg";
import { Container } from "react-bootstrap";
import "./BuyAssetMarketplace.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import ExoploreProductBanner from "../ExploreProductBanner/ExoploreProductBanner";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import { NavHashLink } from "react-router-hash-link";
import CategoryView from "../CategoryView/CategoryView";

const BuyAssetMarketplace = () => {
  const location = useLocation();
  const history = useHistory();
  const customPropValue = location.state?.customProp;

  let navData = [
    { to: "/asset-marketplace", link: "For Farm Enterprise" },
    { to: "/asset-marketplace-sell", link: "For Sellers" },
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

  const [rawProduct, setRawProduct] = useState(true);
  const [semiProduct, setSemiProduct] = useState(false);
  const [processedProduct, setProcessedProduct] = useState(false);
  const [AnimalData, setAnimalData] = useState(false);
  const [feedProcessingMachinery, setFeedProcessingMachinery] = useState(false);

  const [userType, setUserType] = useState(["FOOD-PROCESSOR"]);

  // useEffect(() => {
  //   if (location.hash === "#processing-machinery") {
  //     setRawProduct(true);
  //     setSemiProduct(false);
  //     setProcessedProduct(false);
  //   } else if (location.hash === "#farming-machinery") {
  //     setRawProduct(false);
  //     setSemiProduct(true);
  //     setProcessedProduct(false);
  //   } else if (location.hash === "#agri-assets") {
  //     setRawProduct(false);
  //     setSemiProduct(false);
  //     setProcessedProduct(true);
  //   }
  // }, [location.hash]);

  const rawProductHandler = () => {
    setRawProduct(true);
    setSemiProduct(false);
    setProcessedProduct(false);
    setAnimalData(false);
    setFeedProcessingMachinery(false);

    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#processing-machinery",
    //   state: { customProp: customPropValue },
    // });
  };

  const semiProductHandler = () => {
    setSemiProduct(true);
    setRawProduct(false);
    setProcessedProduct(false);
    setAnimalData(false);
    setFeedProcessingMachinery(false);

    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#farming-machinery",
    //   state: { customProp: customPropValue },
    // });
  };

  const processedProductHandler = () => {
    setProcessedProduct(true);
    setRawProduct(false);
    setSemiProduct(false);
    setAnimalData(false);
    setFeedProcessingMachinery(false);


    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#agri-assets",
    //   state: { customProp: customPropValue },
    // });
  };

  const AnimalHusbandryHandler = () => {
    setProcessedProduct(false);
    setRawProduct(false);
    setSemiProduct(false);
    setAnimalData(true);
    setFeedProcessingMachinery(false);

  };


  const FeedProcessingMachineryHandler = () =>{
    setFeedProcessingMachinery(true);
    setProcessedProduct(false);
    setRawProduct(false);
    setSemiProduct(false);
    setAnimalData(false);
  }

  const categoryImage = {}
  return (
    <>
      <HeaderComponent className="noShadow" />
      <SecondaryNavbar props={navData} />
      <CategoryView MarketCategoryType="AgriAssetMarketplace" categoryImage= {categoryImage}/>
      <FooterComponent />
    </>
  );
};

export default BuyAssetMarketplace;
