import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import ExoploreNetworkBanner from './ExploreNetworkBanner'
import ExploreNetworkContent from './ExploreNetworkContent'
import { Container } from '@material-ui/core'

export default function ExploreNetwork() {
  return (
    <>
    <HeaderComponent/>
    <ExoploreNetworkBanner/>
    <Container className="mb-5">
    <ExploreNetworkContent/>

</Container>
    <FooterComponent/>
    </>
  )
}
