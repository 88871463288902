import React from "react";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import "./SHG.css"

import SHGNeeds from "./SHGNeeds";
import SHGServices from "./SHGServices";

export default function SHG() {
  return (
    <>
      <HeaderComponent />
        <UpdatesBanner className="ap-shg" bannerText="NGOs" />
        <SHGNeeds/>
        <SHGServices/>


      <FooterComponent />
    </>
  );
}
