import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
// import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';
import POServices from './components/POServices/POServices';
import Provenace from './components/POServices/Provenace';
import * as serviceWorker from './serviceWorker';
import ExploreNetwork from './components/ExploreNetwork/ExploreNetwork';
import AgriStack from './components/AgriStack/AgriStackComponent';

import WebSiteApp from './components/WebSite/App';
import Farmer from './components/Farmer/FarmerComponent';
/* import Grower from './components/Grower/GrowerComponent'; */
import Producer from './components/Producer/ProducerComponent';
import About from "./components/AboutUs/AboutusComponent"
import AuthRoute from './components/Auth/AuthRoute';
// import MainRoute from './components/Main/MainRoute';
import MainRoute from './components/Main/components/Main/MainRoute';
// import AgriStack from './components/AgriStack/AgriStackComponent';
import BusinessPartner from './components/BusinessPartner/BusinessPartner';
import {NotificationContainer} from "react-notifications";
// import AppsComponent from './components/Apps/AppsComponent';
// import BecomeBuyerComponent from './components/BecomeBuyer/BecomeBuyer';
import ServicesComponent from './components/Services/ServicesComponent';
import GbrFormsComponent from './components/GBRForms/GbrFormsComponent';
import TermsComponent from './components/T&C/Terms';
import PrivacyComponent from './components/Privacy/Privacy';
import PricingTabs from './components/pricingTabs/PricingTabs';
import ExploreCareProducts from "./components/ExploreCareProducts/ExploreCareProducts"
import "react-notifications/lib/notifications.css";
import './index.css';
import ExploreProducts from './components/ExploreProducts/ExploreProducts';
import ProcessedProduct from './components/ProceesedProducts/ProcessedProduct';
import RawProducts from './components/RawProducts/RawProducts';
import AgriAssets from './components/AgriAssets/AgriAssets';
import { ScrollToTop } from './components/ScrollTo/ScrollToTop';
import Club from './components/Club/Club';
import MainService from './components/Main/components/Main/Common/MainService';
import GBREngage from './components/gbrEngage/GBREngage';
import IAA from './components/IAA/IAA';
import ExploreInvestement from './components/Investments/ExploreInvestement';
import CBNNetwork from './components/WebSite/CBNNetork/CBNNetwork';
import ForFarmers from './components/ForFarmers/ForFarmers';
import ForAgriEntreprenuer from './components/ForAgriEntreprenuer/ForAgriEntreprenuer';
import Agent from './components/Agent/Agent';
import CommunityService from './components/CommunityService/CommunityService';
import AssetService from './components/AssetService/AssetService';
import FieldSupportService from './components/FieldSupportService/FieldSupportService';
import ExploreTechnology from './components/ExploreTechnology/ExploreTechnology';
import ProcessingService from "./components/ProcessingService/ProcessingService";
import ForManufactures from './components/ForManufactures/ForManufactures';
import ExploreAnimalCare from './components/ExploreAnimalCare/ExploreAnimalCare';
import ExplorePlantCare from './components/ExplorePlantCare/ExplorePlantCare';
import PlantAnimalCare from './components/PlantAnimalCare/PlantAnimalCare';
import ForAMMC from './components/ForAAMC/ForAAMC';
import ForAAMC from './components/ForAAMC/ForAAMC';
import ProductionNetwork from './components/WebSite/ProductionNetwork/ProductionNetwork';
import OnMarketplaceSell from './components/OnMarketplaceSell/OnMarketplaceSell';
import SocialMarketplace from './components/SocialMarketplace/SocialMarketplace';
import RFQ from './components/RFQ/RFQ';
import ExploreFoodProcessor from './components/WebSite/ExploreFoodProcessor/ExploreFoodProcessor';
import LandingPage from './components/WebSite/LandingPage/LandingPage';
import ContractManufacturing from './components/ContractManufacturing/ContractManufacturing';
import ContractFarming from './components/ContractFarming/ContractFarming';
import Tender from "./components/Tender/Tender";
import TimelyDelivery from './components/TimelyDelivery/TimelyDelivery';
import ManufacturingOnDemand from './components/ManufacturingOnDemand/ManufacturingOnDemand';
import IAAMarketplace from './components/IAAMarketplace/IAAMarketplace';
import RetailInvestors from './components/RetailInvestors/RetailInvestors';
import InstitutionalInvestors from './components/InstitutionalInvestors/InstitutionalInvestors';
import ForFarmerFranchise from './components/ForFarmerFranchise/ForFarmerFranchise'
import AgriBusiness from './components/AgriBusiness/AgriBusiness';
import Brands from './components/Brands/Brands';
import ABNComponent from './components/ABNComponent/ABNComponent';
import ForNetworKOperators from './components/ForNetworKOperators/ForNetworKOperators';
import './config'
import AAMCNetwork from './components/WebSite/AAMCNetwork/AAMCNetwork';
import MarketPlaceQuote from './components/MarketPlaceQuote/MarketPlaceQuote';
import AssetForInvestment from './components/AssetForInvestment/AssetForInvestment';
import AgriBusinessCard from './components/AgriBusinessCard/AgriBusinessCard';
import AgriBusinessCompany from './components/AgriBusinessCompany/AgriBusinessCompany';
import AgriProducerPricing from './components/AgriProducerPricing/AgriProducerPricing';
import AEOnboardingService from './components/AEOnboardingService/AEOnboardingService';
import BlogDetails from './components/BlogDetails/BlogDetails';
import Blog from './components/Blog/Blog';
import AgroDealers from './components/AgroDealers/AgroDealers';
import Request from './components/RequestAc/Request'
import CommunityPartners from './components/CommunityPartners/CommunityPartners';
import AdvertisementService from './components/AdvertisementService/AdvertisementService';
import ForAgriFoodManufacturers from './components/ForAgriFoodManufacturers/ForAgriFoodManufacturers';
import AROnboardingService from './components/AEOnboardingService/AROnboardingService';
// const { user } = MainService.getLocalData();
// const {  username,parentId } = user;
 import {CartItemProvider} from './components/Main/context/CartItemContext'
import SigninPageComponent from './components/Auth/Signin/SigninPageComponent';
import AgribusinessMain from './components/AgribusinessMain/AgribusinessMain';
import CBO from './components/CBO/CBO';
import DealerTypes from './components/DealerTypes/DealerTypes';
import ManufacturingOnboard from './components/AEOnboardingService/ManufacturingOnboard';
import AgriFoodManufactureMoD from './components/ForAgriFoodManufacturers/AgriFoodManufactureMoD';
import FFS from './components/FFS/FFS';
import NGO from './components/AgriProducers/NGO/NGO';
import FPO from './components/AgriProducers/FPO/FPO';
import SHG from './components/AgriProducers/SHG/SHG';
import CS from './components/AgriProducers/CS/CS';
import Farmers from './components/AgriProducers/Farmer/Farmers';
import FinancialService from './components/AgriProducers/FinancialService/FinancialService';
import CareServices from './components/AgriProducers/CareServices/CareServices';
import AssetMarketService from './components/AgriProducers/AssetMarketService/AssetMarketService';
import CMNNetwork from './components/WebSite/AAMCNetwork/CMNNetwork';
import CBOClubs from './components/CBOClubs/CBOClubs';
import TechStack from './components/TechStack/TechStack';
import CareAgroDealers from './components/CareAgroDealers/CareAgroDealers';
import ARNNetwork from './components/WebSite/ARNNetwork/ARNNetwork';
import LTNNetwork from './components/WebSite/LTNNetwork/LTNNetwork';
import Agency from './components/Agencies/Agency';
import ForInputProviders from './components/WebSite/ForInputProviders/ForInputProviders';
import LogisticsProviders from './components/LogisticProviders/LogisticsProviders';
import LogisticsProvidersFleetServices from './components/LogisticProviders/LogisticsProvidersFleetServices';
import LogisticsProvidersFacilitationServices from './components/LogisticProviders/LogisticsProvidersFacilitationServices';
import AgriMinerComponent from './components/AgriMiner/AgriMinerComponent';
import LogisticProvidersACNServices from './components/LogisticProviders/LogisticProvidersACNServices';
import LogisticProvidersMarketplaceService from './components/LogisticProviders/LogisticProvidersMarketplaceService';
import ForBuyers from './components/ForBuyers/ForBuyers';
import DSNService from './components/ForBuyers/DSNService';
import DSNonBoardingService from './components/ForBuyers/DSNonBoardingService';
import ForAdvisors from './components/ForAdvisors/ForAdvisors';
import BuyAssetMarketplace from './components/BuyAssetMarketplace/BuyAssetMarketplace';
import BuyMarketPlaceSell from './components/BuyAssetMarketplace/BuyMarketPlaceSell';
import BillingService from './components/BillingService/BillingService';
import BusinessDiversificationService from './components/BusinessDiversificationService/BusinessDiversificationService';
import ExploreProductB2Bbuyers from './components/ExploreProducts/ExploreProductB2Bbuyers';
import ExploreAgriRetailer from './components/ExploreAgriRetailer/ExploreAgriRetailer';
import FarmMachineryMarketplace from './components/FarmMachineryMarketplace/FarmMachineryMarketplace';
import FarmMachineryMarketplaceForPIM from './components/FarmMachineryMarketplace/FarmMachineryMarketplaceForFMP';
import FarmMachineryMarketplaceForFMP from './components/FarmMachineryMarketplace/FarmMachineryMarketplaceForFMP';
import ForFMP from './components/ForFMP/ForFMP';
import BulkOnMarketplaceSell from './components/ExploreProducts/BulkOnMarketplaceSell';
import FECapitalProviders from './components/OurPartners/FECapitalProviders';
import FECustomer from './components/OurPartners/FECustomer';
import FEPartner from './components/OurPartners/FEPartners';
import QualityAssurance from "./components/POServices/QualityAssurance"
import B2BonMarketplace from './components/ExploreProducts/B2BonMarketplace';
import D2ConMarketplace from './components/ExploreProducts/D2ConMarketplace';
import CollaborativeFarming from './components/POServices/CollaborativeFarming';

let ConsumerAdmin;
let ClientId;
let ClientSecretKey;
let ClientName;
let AssetverticalID;


if(process.env.REACT_APP_DEVELOPMENT_ENV === "production"){
    ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_PRODUCTION
    ClientId = process.env.REACT_APP_CLIENT_ID_PRODUCTION
    ClientSecretKey = process.env.REACT_APP_CLIENT_SECRET_PRODUCTION
    ClientName = process.env.REACT_APP_NAME_PRODUCTION
    AssetverticalID = process.env.REACT_APP_ASSETVERTICAL_PRODUCTION
 }
 else if (process.env.REACT_APP_DEVELOPMENT_ENV === "staging"){
    ConsumerAdmin =  process.env.REACT_APP_CONSUMER_ADMIN_STAGING
    ClientId = process.env.REACT_APP_CLIENT_ID_STAGING
    ClientSecretKey = process.env.REACT_APP_CLIENT_SECRET_STAGING
    ClientName = process.env.REACT_APP_NAME_STAGING
    AssetverticalID = process.env.REACT_APP_ASSETVERTICAL_STAGING

 }



window.AppConfigData = {
   ConsumerAdmin:ConsumerAdmin,
   CBNRealm: ConsumerAdmin,
   AssetVerticalId:AssetverticalID,
   AssetVerticalFriendlyName: 'ANIMAL',
   ApiUrlMappCBN: window.appConfig.cbn,


   ClientName: ClientName,
   ClientId:ClientId,
   RedirectUrl: '/',
   Realm: ConsumerAdmin,
   EnterpriseId: ConsumerAdmin,
   ClientSecretKey: ClientSecretKey,
   ApiUrlMapp: window.appConfig,
   analytics: {
       google: window.googleAnalytics
   }
//     //ClientName: 'Agri-App',
//     //Realm: 'Agri-Admin',
//     //EnterpriseId: 'Agri-Admin',
//     //UserType: 'Agri-Investor',
//     //ClientId:'2aca444a-d378-4661-947a-4f75d102324c',
//     ClientName: 'Agri-App',
//     Realm: 'consumer2',
//     EnterpriseId: 'consumer2',
//     UserType: 'AssetOEM',
//     ClientId:'30c667be-1aa3-4f0d-94be-a4176d93558a',
//     RedirectUrl: '/',
//    // ClientSecretKey: 'dc13ff6f-7f55-4779-8a45-f3ab52d9b8aa',
//     ClientSecretKey: '6511ea61-2b7a-47f1-b548-0397c68828ad',
//     AssetVerticalId: 'cffa5ffe-f15e-4a27-9a9e-7bf8b5c5d110',
//     AssetVerticalFriendlyName: 'ANIMAL',
//     ApiUrlMapp: window.appConfig,
//     analytics: {
//         google: window.googleAnalytics
//     }
}



// window.AppConfigData = {
//     ConsumerAdmin:"consumer2",
//     CBNRealm: parentId,
//     AssetVerticalId: 'cffa5ffe-f15e-4a27-9a9e-7bf8b5c5d110',
//     AssetVerticalFriendlyName: 'ANIMAL',
//     ApiUrlMappCBN: window.appConfig.cbn,


//     ClientName: 'GBR1',
//     ClientId:'4f4bb68c-f310-4742-9dbd-dcbbc396f9a3',
//     RedirectUrl: '/',
//     Realm: parentId,
//     EnterpriseId: 'consumer2',
//     ClientSecretKey: '56b50768-f088-40e5-b015-9ec5eefdb213',
//     ApiUrlMapp: window.appConfig,
//     analytics: {
//         google: window.googleAnalytics
//     }
// //     //ClientName: 'Agri-App',
// //     //Realm: 'Agri-Admin',
// //     //EnterpriseId: 'Agri-Admin',
// //     //UserType: 'Agri-Investor',
// //     //ClientId:'2aca444a-d378-4661-947a-4f75d102324c',
// //     ClientName: 'Agri-App',
// //     Realm: 'consumer2',
// //     EnterpriseId: 'consumer2',
// //     UserType: 'AssetOEM',
// //     ClientId:'30c667be-1aa3-4f0d-94be-a4176d93558a',
// //     RedirectUrl: '/',
// //    // ClientSecretKey: 'dc13ff6f-7f55-4779-8a45-f3ab52d9b8aa',
// //     ClientSecretKey: '6511ea61-2b7a-47f1-b548-0397c68828ad',
// //     AssetVerticalId: 'cffa5ffe-f15e-4a27-9a9e-7bf8b5c5d110',
// //     AssetVerticalFriendlyName: 'ANIMAL',
// //     ApiUrlMapp: window.appConfig,
// //     analytics: {
// //         google: window.googleAnalytics
// //     }
// }

const theme = createMuiTheme(
    {
        palette: {
            primary: {main: '#3ECF8E'},
            secondary: {main: '#2F80ED'},
            danger: {main: '#BF360C', shadow: 'rgba(191,54,12 ,.3)'},
        },
        status: {
            danger: 'orange',
        }
    }
);

try{
    ReactGA.initialize(window.AppConfigData.analytics.google.accountId, { debug: false });
    ReactGA.pageview(window.location.pathname + window.location.search);
    //console.log(window.location.pathname + window.location.search);
}catch(e){
    console.error(e);
}

class IndexComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            // history: useHistory()
        }
    }

    
    componentDidMount(){
        localStorage.setItem("Sapna", true);
}

    componentDidUpdate(prevProps, prevState){
       
        if(prevProps.location !== this.props.location){
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    // createAccessToken(){
    //     fetch(
    //         `${window.appConfig.PublicAccessToken}${window.appConfig.TokenRelam}`,
    //         {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
                 
    //           },
    //           body:JSON.stringify( {
    //             client_name:"GBR1",
    //             client_secret:"56b50768-f088-40e5-b015-9ec5eefdb213"
    //           })
    //         }
    //       ).then((res) => res.json())
    //     .then((result) => {
    //         this.setState({
    //          accessToken: result.access_token,
    //         });
    //         localStorage.setItem("GlobalAccess",result.access_token);

    //     })
    //         .catch((err) => console.log("err", err));
    //   }
    createAccessToken(){
        fetch(
            `${window.appConfig.PublicAccessToken}${window.appConfig.TokenRelam}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                 
              },
              body:JSON.stringify( {
                client_name:ClientId,
                client_secret:ClientSecretKey
              })
            }
          ).then((res) => res.json())
        .then((result) => {
            this.setState({
           });
            localStorage.setItem("GlobalAccess",result.access_token);

        })
            .catch((err) => console.log("err", err));
      }
      componentDidMount(){
        this.createAccessToken();
        // localStorage.setItem("Sapna", false);

      }

    render(){
        return (
            // <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CartItemProvider>
                    <Router>
                    <ScrollToTop>
                        <Switch>

                        {/* <Route exact path="/" component={(args) => <SigninPageComponent{...args}/>} /> */}

                        <Route exact path="/dummy" component={(args) => <LandingPage{...args}/>} />
                            <Route exact path="/farm-enterprise" component={(args) => <ForAAMC {...args}/>} />

                            {/* <Route exact path="/" component={(args) => <ForAAMC {...args}/>} /> */}
                            <Route exact path="/agri-producers" component={(args) => <WebSiteApp {...args}/>} />
                            <Route exact path='/partners' component={(args)=><CommunityPartners {...args}/>} />
                            <Route exact path='/agency' component={(args)=><Agency {...args}/>} />
                            <Route exact path='/agriminer' component={(args)=><AgriMinerComponent {...args}/>} />

                            <Route exact path="/Farmer" component={(args) => <Farmer {...args}/>} />
                            <Route exact path="/About" component={(args) => <About {...args}/>} />
                            <Route exact path="/ComingSoon" component={(args) => <Request {...args}/>} />
                            <Route exact path='/onMarketplace' component={(args)=><ExploreProducts {...args}/>}/>
                            <Route exact path='/bulk-onmarketplace' component={(args)=><ExploreProductB2Bbuyers {...args}/>}/>
                            <Route exact path='/bulk-onmarketplace-sell' component={(args)=><BulkOnMarketplaceSell {...args}/>}/>

                            <Route exact path="/EngagementServices" component={(args) => <GbrFormsComponent {...args}/>} />
                            <Route path="/auth" component={(args) => <AuthRoute {...args}/>} />
                            <Route path="/main" component={(args) => <MainRoute {...args}/>} />
                            <Route exact path='/Services' component={(args)=> <ServicesComponent {...args}/>}/>
                            <Route exact path='/Terms&Conditions' component={(args)=> <TermsComponent {...args}/>}/>
                            <Route exact path='/PrivacyPolicy' component={(args)=> <PrivacyComponent {...args}/>}/>
                            <Route exact path='/ProcessedProducts' component={(args)=><ProcessedProduct {...args}/>}/>
                            <Route exact path='/RawProducts' component={(args)=><RawProducts {...args}/>}/>
                            <Route exact path='/AgriAssets' component={(args)=><AgriAssets {...args}/>}/>
                            <Route exact path='/Pricing' component={(args)=><PricingTabs {...args}/>}/>
                            <Route exact path='/Club' component={(args)=><Club {...args}/>}/>
                            {/* <Route path="/cbnmain" component={(args) => <CBNMainRoute {...args}/>} /> */}
                            <Route exact path='/Community' component={(args)=><CommunityService {...args}/>} />
                            <Route exact path='/IAA' component={(args)=><IAA {...args}/>} />
                            <Route exact path='/CBNNetwork' component={(args)=><CBNNetwork {...args}/>} />
                            <Route exact path='/explore-institutional-lenders' component={(args)=><ExploreInvestement {...args}/>} />
                            <Route exact path='/explore-agri-retailers' component={(args)=><ExploreAgriRetailer {...args}/>} />

                            <Route exact path='/ForGrowers' component={(args)=><ForFarmers {...args}/>} />
                            <Route exact path='/ProductionManager' component={(args)=><ForAgriEntreprenuer {...args}/>} />
                            <Route exact path='/FarmerFranchise' component={(args)=><ForFarmerFranchise {...args}/>} />
                            <Route exact path='/ARNNetwork' component={(args)=><ARNNetwork {...args}/>} />
                            <Route exact path='/LTNNetwork' component={(args)=><LTNNetwork {...args}/>} />
                            <Route exact path="/CF-PO-Services" component={(args)=><POServices {...args}/>} />
                            <Route exact path="/Provenance" component={(args)=><Provenace {...args}/>} />
                            <Route exact path='/Agent' component={(args)=><Agent {...args}/>} />
                            <Route exact path='/Profit-generating-Services' component={(args)=><AssetService {...args}/>} />
                            <Route exact path='/Field-Support' component={(args)=><FieldSupportService {...args}/>} />
                            <Route exact path='/ExploreTechnology' component={(args)=><ExploreTechnology {...args}/>} />
                            <Route exact path='/GrowthService' component={(args)=><ProcessingService {...args}/>} />
                            <Route exact path='/ForBrands' component={(args)=><ForManufactures {...args}/>} />
                            <Route exact path='/ExploreAnimalCare' component={(args)=><ExploreAnimalCare {...args}/>}/>
                            <Route exact path='/ExplorePlantCare' component={(args)=><ExplorePlantCare {...args}/>}/>
                            <Route exact path='/Care' component={(args)=><PlantAnimalCare {...args}/>}/>
                            <Route exact path='/' component={(args)=><ForAAMC {...args}/>}/>
                            <Route exact path='/PNNetwork' component={(args)=><ProductionNetwork {...args}/>}/>
                            <Route exact path='/AAMC' component={(args)=><AAMCNetwork {...args}/>}/>
                            <Route exact path="/onMarketplaceRFQ" component={(args)=><MarketPlaceQuote {...args}/>} />

                            <Route exact path="/onMarketplaceSell" component={(args)=><OnMarketplaceSell {...args}/>} />
                            <Route exact path="/SocialCFonMarketplace" component={(args)=><SocialMarketplace {...args}/>} />
                            <Route exact path="/RFQ" component={(args)=><RFQ {...args}/>} />
                            <Route exact path="/SocialCMonMarketplace" component={(args)=><ContractManufacturing {...args}/>} />
                            <Route exact path="/ContractFarming1" component={(args)=><ContractFarming {...args}/>} />
                            <Route exact path="/Tender" component={(args)=><Tender {...args}/>} />
                            <Route exact path="/TimelyDelivery" component={(args)=><TimelyDelivery {...args}/>} />
                            <Route exact path="/social-cf-onmarketplace" component={(args)=><AgriBusiness {...args}/>} />
                            <Route exact path="/Brands" component={(args)=><Brands {...args}/>} />
                            <Route exact path="/MoD" component={(args)=><ManufacturingOnDemand {...args}/>} />
                            <Route exact path="/iaaMarketplace" component={(args)=><IAAMarketplace {...args}/>} />
                            <Route exact path="/RetailInvestors" component={(args)=><RetailInvestors {...args}/>} />
                            <Route exact path="/ForFMP" component={(args)=><ForFMP {...args}/>} />

                            <Route exact path="/social-iaaMarketplace-for-institutional-lenders" component={(args)=><InstitutionalInvestors {...args}/>} />
                            <Route exact path="/InvestableAssets" component={(args)=><AssetForInvestment {...args}/>} />
                            <Route exact path="/agri-business" component={AgriBusinessCompany}/>
                            <Route exact path="/agri-producer-pricing" component={AgriProducerPricing}/>
                            <Route exact path="/AE-onbarding" component={AEOnboardingService}/>
                            <Route exact path="/AR-onbarding" component={AROnboardingService}/>
                            <Route exact path='/ForBrands' component={(args)=><ForManufactures {...args}/>} />

                            <Route exact path="/agro-dealers" component={AgroDealers}/>
                            <Route exact path="/ContractFarming" component={ContractManufacturing}/>
                            <Route exact path='/input-providers' component={(args)=><ExploreFoodProcessor {...args}/>} />
                            <Route exact path="/agri-input-providers" component={(args)=><ForInputProviders {...args}/>} />
                            <Route exact path="/agri-input-marketplace" component={ExploreCareProducts}/>
                            <Route exact path="/blogs" component={Blog}/>
                            <Route exact path="/blogDetail/:id" component={BlogDetails}/>
                            <Route exact path="/advertisement" component={(args)=><AdvertisementService {...args}/>} />
                            <Route exact path="/food-processing-enterprises" component={(args)=><ForAgriFoodManufacturers {...args}/>} />
                            <Route exact path='/ExploreNetwork' component={(args)=><ExploreNetwork {...args}/>} />
                            <Route exact path="/ExploreAPIs" component={(args) => <AgriStack {...args}/>}/>
                            <Route exact path='/customized-abn-network' component={(args)=><ABNComponent {...args}/>} />
                            <Route exact path='/DTNNetwork' component={(args)=><ProductionNetwork {...args}/>} />
                            <Route exact path='/agribusiness' component={(args)=><AgribusinessMain {...args}/>} />
                            <Route exact path='/CBO' component={(args)=><CBO {...args}/>} />
                            <Route exact path='/agro-dealers-list' component={(args)=><DealerTypes {...args}/>} />
                            {/* <Route exact path='/cm-onboarding' component={(args)=><CMOnboardingService {...args}/>} /> */}
                            <Route exact path='/cm-onboarding' component={(args)=><ManufacturingOnboard {...args}/>} />
                            <Route exact path="/manufacturing-on-demand" component={(args)=><AgriFoodManufactureMoD {...args}/>} />
                            <Route exact path="/FFS" component={(args)=><FFS {...args}/>} />
                            <Route exact path="/quality-assurance" component={(args)=><QualityAssurance {...args}/>} />


                            <Route exact path="/explore-farmer" component={(args)=><Farmers {...args}/>} />
                            <Route exact path="/explore-ngo" component={(args)=><NGO {...args}/>} />
                            <Route exact path="/explore-fpo" component={(args)=><FPO {...args}/>} />
                            <Route exact path="/explore-shg" component={(args)=><SHG {...args}/>} />
                            <Route exact path="/explore-cs" component={(args)=><CS {...args}/>} />
                            <Route exact path="/financial-services" component={(args)=><FinancialService {...args}/>} />
                            <Route exact path="/care-services" component={(args)=><CareServices {...args}/>} />
                            <Route exact path="/asset-market-services" component={(args)=><AssetMarketService {...args}/>} />
                            <Route exact path="/cmn-services" component={(args)=><CMNNetwork {...args}/>} />
                            <Route exact path="/cbo-clubs" component={(args)=><CBOClubs {...args}/>} />

                            <Route exact path="/our-tech-stack" component={(args)=><TechStack {...args}/>} />
                            <Route exact path="/for-network-operators" component={(args)=><ForNetworKOperators {...args}/>} />
                            <Route exact path="/for-agrocare-market-seller" component={(args)=><CareAgroDealers {...args}/>} />
                            <Route exact path="/logistic-providers" component={(args)=><LogisticsProviders {...args}/>} />
                            <Route exact path="/fleet-services" component={(args)=><LogisticsProvidersFleetServices {...args}/>} />
                            <Route exact path="/transport-facilitation-services" component={(args)=><LogisticsProvidersFacilitationServices {...args}/>} />
                            <Route exact path="/acn" component={(args)=><LogisticProvidersACNServices {...args}/>} />
                            <Route exact path="/agritransport-marketplace" component={(args)=><LogisticProvidersMarketplaceService {...args}/>} />
                            <Route exact path="/b2b-buyers" component={(args)=><ForBuyers {...args}/>} />
                            <Route exact path="/dsn-service" component={(args)=><DSNService {...args}/>} />
                            <Route exact path="/dsn-onboarding-service" component={(args)=><DSNonBoardingService {...args}/>} />
                            <Route exact path="/advisors" component={(args)=><ForAdvisors {...args}/>} />
                            <Route exact path="/asset-marketplace" component={(args)=><BuyAssetMarketplace {...args}/>} />
                            <Route exact path="/asset-marketplace-sell" component={(args)=><BuyMarketPlaceSell {...args}/>} />
                            <Route exact path="/billing-service" component={(args)=><BillingService {...args}/>} />
                            <Route exact path="/business-diversification-service" component={(args)=><BusinessDiversificationService {...args}/>} />
                            <Route exact path="/farm-machinery-marketplace" component={(args)=><FarmMachineryMarketplace {...args}/>} />
                            <Route exact path="/farm-machinery-marketplace-for-farm-machinery-providers" component={(args)=><FarmMachineryMarketplaceForFMP {...args}/>} />
                            <Route exact path="/farm-enterprise-vendors" component={(args)=><FEPartner {...args}/>} />
                            <Route exact path="/farm-enterprise-customers" component={(args)=><FECustomer {...args}/>} />
                            <Route exact path="/farm-enterprise-providers" component={(args)=><FECapitalProviders {...args}/>} />
                            <Route exact path="/b2b-onmarketplace" component={(args)=><B2BonMarketplace {...args}/>} />
                            <Route exact path="/d2c-onmarketplace" component={(args)=><D2ConMarketplace {...args}/>} />
                            <Route exact path="/collaborative-farming" component={(args)=><CollaborativeFarming {...args}/>} />

                            <Route component={(args) => <Redirect to={"/"} />} />
                        </Switch>
                        </ScrollToTop>

                    </Router>
                    </CartItemProvider>

                    <NotificationContainer />

                </ThemeProvider>
            // </StyledEngineProvider>
        );
    }
}

ReactDOM.render(<IndexComponent />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

