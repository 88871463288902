import React, { useState } from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import CTA from "../CTA/CTA";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForManufacturers.css";
import ManufacturersBenefits from "./ManufacturersBenefits";
import ManufacturesForm from "./ManufacturesForm";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import ManufacturesRoadMap from "./ManufacturesRoadMap";
import ManufacturerServices from "./ManufacturerServices";
import BrandCTA from "../BrandCTA/BrandCTA";
import MSMECoustomerBenefit from "../WebSite/MSMECoustomerBenefit/MSMECoustomerBenefit";
import BrandChallenges from "./BrandChallenges";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import CBOPricing from "../pricingTabs/CBOPricing";
export default function ForManufactures() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
        bannerText={[
          "Want to become a part of Digital Trust Network to create Differentiated,",
          <br className="d-none d-lg-block"></br>,
          "Reliable & Authentic products from Agri-assets",
        ]}
      
        
        
        />
      {/* <div className="main-wrapper2">
        <BrandChallenges />
      </div> */}
      {/* <Container>
        <SolutionEcosystem
        bold="true"
          type="Brands"
          bodycopy="FEN Network provides an ecosystem for brands.
          "
        />
      </Container> */}
      <div className="main-wrapper2">
        <Container>
          <ManufacturerServices />
        </Container>
      </div>
      {/* <div className="for-manufacturers-wrapper"> */}

      {/* <ManufacturesRoadMap /> */}
      {/* </div> */}

      <Container className="mt-4">
        <MSMECoustomerBenefit />
      </Container>

      {/* <div className="subSectionWrapper pt-0">
        <Container>
              <CTA
               href="/auth/3/signup"
               heading="Want to access Contract Manufacturing Services"
               btnText="Register now for free"
               onClick={()=>{
                 localStorage.setItem("userType", "BRAND")
                             }}
              />
              </Container>
            </div>  */}
            {/* <Container>
<h2 className="container-title text-center "> Pricing</h2>

<CBOPricing/>

          </Container> */}
                 <FooterComponent />
    </>
  );
}
