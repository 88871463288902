import React from "react";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import FarmersServices from "../Farmer/FarmersServices";
import "./NGO.css"
import NGONeeds from "./NGONeeds";
import NGOServices from "./NGOServices";

export default function NGO() {
  return (
    <>
      <HeaderComponent />
        <UpdatesBanner className="ap-ngo" bannerText="NGOs" />
        <NGONeeds/>
        <NGOServices/>


      <FooterComponent />
    </>
  );
}
