import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import img4 from "../../images/CustomerBenefit/resource.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function ABNNetworkBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Improve Revenue",
      bodycopy: "FEN Network enhances revenue for partners through improved network performance and reliability, leading to increased customer satisfaction and higher usage rates.",
    },
    {
      img: img2,
      title: "Expand Business",
      bodycopy: "Expanding into the agrifood sector benefits network operators by diversifying their clientele, increasing revenue streams, and fostering business growth.",
    },
    {
      img: img3,
      title: "New Agri-Investment class",
      bodycopy:
        "Enabling partners to introduce an Agri-investment class for their users, provides a valuable benefit by expanding investment options, diversifying portfolios, and fostering financial inclusivity within the agricultural sector.",
    },
    {
      img: img1,
      title: "Export Ease",
      bodycopy: "Seamless export processes facilitate effortless international market access for farm enterprise."
    },
    // {
    //   img: img3,
    //   title: " Own Club",
    //   bodycopy: "Establishing an exclusive club empowers agribusinesses to trade locally while efficiently identifying optimal sourcing partners.",
    // },
    {
      img: img4,
      title: "Improve Yield",
      bodycopy: "Enhanced yield and quality management optimize farm enterprise assets for greater profitability and sustainability.",
    },
    // {
    //   img: img2,
    //   title: "New Investment",
    //   bodycopy: "Trading assets for investments enables agribusinesses to unlock capital for growth and expansion.",
    // },


    // {
    //     img: img4,
    //     title: "Access to Machinery",
    //     bodycopy: "Accessing top machinery providers empowers agribusinesses to streamline operations and boost efficiency.",
    //   },
      // {
      //   img: img2,
      //   title: "Expert Guidance",
      //   bodycopy: "Connecting with top advisors offers agribusinesses invaluable support for navigating documentation, obtaining organic certifications, accessing government schemes, and ensuring legal compliance.",
      // },

      {
        img: img4,
        title: "Transportation Choice",
        bodycopy: "The ability to access transport of their choice provides farm enterprise with flexibility and efficiency in logistics management, ultimately optimizing their operations.",
      },
      // {
      //   img: img2,
      //   title: "Tender Advantage",
      //   bodycopy: "Accessing government and exporter tenders empowers agribusinesses with lucrative opportunities for growth and collaboration.",
      // },
  ];
  return (
    <div className="main-wrapper2">
      <h2 className="text-center container-title mb-4">
         Benefits
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
