import React from "react";
import Icon1 from "../../images/CustomerBenefit/return-on-investment.svg"
import Icon2 from "../../images/CustomerBenefit/quality-assurance.svg"
import Icon3 from "../../images/CustomerBenefit/time-manager.svg"
import Icon4 from "../../images/CustomerBenefit/no-preservatives.svg"
import Icon5 from "../../images/CustomerBenefit/traceability.svg"

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function BrandBenefits() {
  const benefits = [
   
    {
      image: Icon1,
      title: "Outsourcing Efficiently",
      bodycopy:
        "By outsourcing contract farming to 3rd parties helps customers to source good quality raw material at affordable cost. This approach allows brands to grow sustainably and remain competitive in a dynamic market environment while meeting customer needs efficiently.",
    },

    {
      image: Icon2,
      title: "Ensured Quality ",
      bodycopy:
        "Ensured quality in farming provides a significant benefit for farm enterprise customers, ensuring product consistency, safety, and customer satisfaction."
    },
    {
      image: Icon3,
      title: "Real Time Visibility ",
      bodycopy:
        "Real-time visibility in farming offers a crucial benefit for farm enterprise customers, enabling them to optimize operations, track production progress, and respond promptly to any issues, thus enhancing efficiency and productivity."
    },

  

    {
      image: Icon5,
      title: " Farm to Factory Traceability ",
      bodycopy:
        "Farm-to-factory traceability offers a significant benefit for food processing enterprise customers (brands and buyers) by ensuring transparency in the supply chain, enhancing product quality, safety, and compliance with regulations, while also building consumer trust and loyalty"
    },

  
  ];
  return (
    <>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title"> Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </>

  );
}
