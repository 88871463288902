import React from 'react';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
import AgribusinessClub from '../Club/AgribusinessClub';

export default function AgribussinessAdditional({msme}) {
    const FarmEnterprise = ["AssetDeveloper"]
    const FoodProcessingEnterprise = ["FOOD-PROCESSOR"]
    const AgroDealers = ["AHSP"]
    const CBO = ["CBO"]
  return (
    <>
          <HomeSubSection
        id="FEN-Investor"
        className3="agribusiness-img"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards--mod"
        descriptionTitle="
                Farm Enterprise"
        description="Farm enterprises should join the FEN network for unparalleled opportunities. By connecting with established brands, they can secure contract farming agreements, ensuring a steady market and access to higher-value markets. Additionally, through FEN, farms enterprise can establish and manage their digital sub-networks, enhancing operational efficiency and communication. With access to a broader marketplace, joining the FEN network empowers farm enterprises to expand their reach, increase sales, and thrive in the agricultural industry.

        "
        description1="Organic farming companies | Agri-asset management companies"
        
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "AssetDeveloper");
            }}

      />

<div className='subSectionWrapper'>
<h2 className='container-title mb-md-5 text-center'>Farm Enterprise</h2>

<AgribusinessClub type = {FarmEnterprise}/>
    </div>
<HomeSubSection
        id="FEN-Investor"
        className3="agri-foodmanufacturer-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards--mod"
        descriptionTitle="
        Food Processing Enterprises"
        description="
        Food processing enterprises benefit significantly from joining the FEN network. It provides access to contract manufacturing opportunities with renowned brands, ensuring a steady stream of business and entry into lucrative markets. Additionally, FEN facilitates the establishment of digital sub-networks, optimizing operations and communication. Membership also grants access to a broader marketplace, enabling enterprises to showcase products to a wider audience and drive growth. In summary, joining FEN empowers food processing enterprises to expand reach, enhance efficiency, and thrive in the competitive food industry landscape.
        "
        description1="Food processor "
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "FOOD-PROCESSOR");
            }}
      />

<div className='subSectionWrapper'>
<h2 className='container-title mb-md-5 text-center'>Food Processing Enterprises</h2>

<AgribusinessClub type = {FoodProcessingEnterprise}/>
    </div>


{/* <HomeSubSection
        id="FEN-Investor"
        className3="cbo-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards--mod"
        descriptionTitle="
        Community Based Organizations"
        description="Community Based Organizations (CBOs) stand to gain immensely from joining the FEN network. Through FEN, they establish vital connections with governmental bodies, facilitating collaboration and access to resources. Additionally, CBOs can create and manage their digital sub-networks, enhancing organizational efficiency and communication. Moreover, membership in the FEN network grants access to a wider marketplace, enabling CBOs to showcase their initiatives and products, fostering local trade and community development. In essence, joining FEN empowers CBOs to amplify their impact, strengthen connections with government entities, and foster local economic growth.
        "
        description1="FPCs | NGOs | PACS | Traders | SHG | CS"
        href2="/auth/3/signup"
        buttoncont2="Register"
                   onClick={() => {
              localStorage.setItem("userType", "CBO");
            }}
      
                 /> */}

{/* <div className='subSectionWrapper'>
<h2 className='container-title mb-md-5 text-center'>CBO</h2>

<AgribusinessClub type = {CBO}/>
    </div> */}
{/* {msme === "true"  && <HomeSubSection
        id="FEN-Investor"
        className3="msme-img"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards--mod"
        descriptionTitle="
        Agri-MSMEs"
        description="Agri-MSMEs are small and medium-sized enterprises operating within the agro sector, encompassing farmers, agri-processing units, input suppliers, storage providers and service providers. They play a vital role in the agro value chain, contributing to economic development, employment, and sustainable agriculture. Support for Agri-MSMEs is crucial for fostering inclusive growth and enhancing the resilience of the agro economy.
"
description1="Agri-Entrepreneurs | Agri-Storage Entrepreneurs |
Agri-Contract Manufacturer | Agri-Transporter
"
href4="http://gbrfarming.com/"
        buttoncont1="For Agri-MSMEs"
       
      />} */}
    </>
  )
}
