import React from "react";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import FarmersNeed from "../Farmer/FarmersNeeds";
import FarmersServices from "../Farmer/FarmersServices";
import "./FPO.css"
import FPONeeds from "./FPONeeds";

export default function Farmers() {
  return (
    <>
      <HeaderComponent />
        <UpdatesBanner className="ap-fpo" bannerText="FPOs" />
        <FPONeeds/>
        <FarmersServices/>


      <FooterComponent />
    </>
  );
}
