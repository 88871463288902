import React from "react";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import CowImg from "../../../images/cowImage.jpg";
import BeeImg from "../../../images/gbrHome/honeybee.webp";

export default function InvestableAssets() {
  return (
    <>
      {" "}
      <div className="pt-4" id="Bee">
        <HomeSubSection
          className1="aboutBgGrey"
          className3="image7"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
      Honey Bee"
          description="FEN includes honey bees as an asset type, allowing agri-producers to efficiently manage and benefit from beekeeping alongside traditional agricultural practices. This integration promotes pollination, honey production, and biodiversity conservation on the farm.
                    "
          src={BeeImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        />
      </div>
    </>
  );
}
