import React from "react";
import Icon1 from "../../images/CustomerBenefit/return-on-investment.svg";
import Icon2 from "../../images/CustomerBenefit/quality-assurance.svg";
import Icon3 from "../../images/CustomerBenefit/time-manager.svg";

import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function FarmEnterpriseCustomerBenefits() {
  const benefits = [
   
    {
      image: Icon1,
      title: "Better RoI",
      bodycopy:
        "Better return on investment (RoI) serves as a significant benefit for farm enterprise capital providers, enhancing their profitability and sustainability.",
    },

    {
      image: Icon2,
      title: "Safe Loan ",
      bodycopy:
        "Safe loans provide capital providers with a low-risk investment option, offering flexibility in repayment and minimizing default risks. This ensures more secure returns while supporting the growth of farm enterprises. their competitiveness and marketability."
    },
    {
      image: Icon3,
      title: "Live monitoring ",
      bodycopy:
        "Live monitoring of invested assets allows capital providers to track the performance and condition of their investments in real-time. This transparency enhances decision-making and reduces financial risks."
    },
  ];
  return (
    <>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </>

  );
}
