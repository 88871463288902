import React from "react";
// import "./ManufacturingOnDemand.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/white-label1.jpg";
import PrivateLabelling from "../../images/brand-images/private-labelling.jpg";
import NewDevelopment from "../../images/brand-images/skills-concept-skills-education-learning-personal-development-competency-business-min.jpg";
import BrandCTA from "../BrandCTA/BrandCTA";
export default function LogisticProvidersMarketplaceService(){
    return(
        <>
        <HeaderComponent/>
        <UpdatesBanner 
         className="MoDBanner text-white"
         bannerText="AgriTransport Marketplace Services"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
  

        <FooterComponent/>
        </>
    )
}