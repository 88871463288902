import React from "react";
import Toast from "light-toast";
import { Container } from "@material-ui/core";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import "../App.css";
import SolutionEcosystem from "../SolutionEcosystem/SolutionEcosystem";

import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import CTA from "../../CTA/CTA";

import ForFarmerBenefits from "../ForFarmerBenefits/ForFarmerBenefits";
import ForFarmerChallenges from "../ForFarmerChallenges/ForFarmerChallenges";
import ForInputProviderChallenges from "../ForInputProviderChallenges/ForInputProviderChallenges";
import InputProviderBenefits from "../InputProviderBenefits/InputProviderBenefits";
import GBRFarmerService from "../../AgriProducers/Farmer/GBRFarmerService";
import CBOPricing from "../../pricingTabs/CBOPricing";
export default function ForInputProviders() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="farmUpdateBanner"
        bannerTitle="Agri Input Providers & Agrodealers"
        bannerbodycopy1={[
          "We help Agri input providers & Agrodealers to expand their markets",
        ]}
        // bannerbodycopy="Brand Recognization | Increased Income | Job Creations | Market Access | Value Addition"
        // buttonText2="Sign in"
        // href2="http://abn.gbrfarming.com/"
      />
      {/* <div className="main-wrapper2">
      <ForInputProviderChallenges/>
    </div> */}

      <SolutionEcosystem
        bold="true"
        type="IP"
        bodycopy="Provides financed machinery and asset ecosystem for agri input providers & agrodealers, institutional lenders and farm enterprises"
      />

      <div className="main-wrapper2">
        <GBRFarmerService />
      </div>

      <InputProviderBenefits />

      <FooterComponent />
    </>
  );
}
