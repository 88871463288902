import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/Network_Operator_-_GBR_Network_-__Challenges_-_06-03-2024_C-removebg-preview.png";
// import production from "../../../images/challangesImg/production (2).svg";
import Ecosystem from "../../images/Revenue Decline.svg";
import Marketing from "../../images/Technological Disruption.svg";
import Brand from "../../images/Limited Connectivity.svg";
import Finance from "../../images/Interoperability Issues.svg";

export default function NetworkOperatorChallenges() {
  const challanges =[{
    title:"Revenue Decline from Traditional Services",
    description:"Revenue from traditional telecom services such as voice calls and SMS messaging is declining due to factors such as the rise of over-the-top (OTT) messaging apps, VoIP services, and bundled service offerings. Telecom operators must find new revenue streams to offset these declines.",
    image:Ecosystem
  },
  {
    title:"Technological Disruption ",
    description:"Rapid advancements in technology, such as 5G, Internet of Things (IoT), artificial intelligence (AI), Blockchain and edge computing, are reshaping the telecom industry. Telecom operators must adapt to these technological changes and invest in upgrading their networks and services to remain competitive.",
    image: Marketing
  },
  // {
  //   title:"Limited Connectivity in Rural Areas",
  //   description:"In many agricultural regions, especially in developing countries, there is limited or no access to reliable telecommunications infrastructure. Telecom operators face challenges in extending their network coverage to remote rural areas, where many agricultural activities take place.",
  //   image: Brand
  // },

  // {
  //   title:"Interoperability Issues ",
  //   description:"In some cases, there may be interoperability issues between different telecommunications networks and agricultural technology systems. This can hinder the adoption and integration of digital solutions for agriculture, such as precision farming, IoT sensors, and agricultural drones.",
  //   image:Finance
  // }

]
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        {" "}
        Network Operator Challenges
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={e.image} className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p>
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}
         
          {/* <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={Finance} className="mb-3" />
                <h6 className="mb-2">Extra Source of Income</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </Col>
           

            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-4 mb-3 ">
                <img src={Ecosystem} className="mb-3" />
                <h6 className="mb-2">Ecosystem</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </Col>
            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Marketing} className="mb-3" />
                <h6 className="mb-2">Marketing</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been
                </p>
              </div>
            </Col>

            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Brand} className="mb-3" />
                <h6 className="mb-2">Brand</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum
                </p>
              </div>
            </Col> */}
          
          </Row>
   
        </Col>
      </Row>
    </Container>
  );
}
