import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";
import FarmMachineryMarketplaceNavbar from "./FarmMachineryMarketplaceNavbar";

export default function FarmMachineryMarketplaceForFMP(){




    return(
        <>
        <HeaderComponent className="noShadow"/>
        <FarmMachineryMarketplaceNavbar/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling farming machinery with FEN"
         buttonText="Sign Up"
         href="auth/3/signup"
         clicked={() => {
            localStorage.setItem("userType", "PMC");
          }} 
        />
        {/* <MarketPlaceQuotePP/> */}
        <FooterComponent/>
        </>
    )
}