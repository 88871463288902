import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import "./AEOnboardingService.css"
import { Container } from 'react-bootstrap'
import AEOnboardingServiceSection from './AEOnboardingServiceSection'
import AROnboardingServiceSection from './AROnboardingServiceSection'
export default function AROnboardingService() {
  return (
    <>
     <HeaderComponent/>
     <UpdatesBanner 
      className="onBoardingBanner text-white"
      bannerText="AR Onboarding Service"/>
      <div className='subSectionWrapper'>
        <AROnboardingServiceSection/>
      </div>
     <FooterComponent/>
    </>
  )
}
