import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ProcessingSubsection from './ProcessingSubsection';
import "./ProcessingService.css";

export default function ProcessingService(props) {
  const customPropValue = props.location?.state?.customProp ;
  // console.log(customPropValue)
  return (
    <>
     <HeaderComponent/>
     <UpdatesBanner
        className="processingServiceBg"
        bannerText={["Business Growth Service"]}
      />
      <ProcessingSubsection customPropValue={customPropValue}/>
     <FooterComponent/>
    </>
  )
}
