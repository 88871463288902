import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import AboutSubSection from '../aboutSubSection/AboutSubSection';
import ContractFarmingImg from "../../images/white-label1.jpg";
import PrivateLabelling from "../../images/brand-images/private-labelling.jpg";
import Supplier from "../../images/brand-images/working-warehouse-loaders-moves-boxes-industrial-min.jpg";
import  Market from "../../images/brand-images/global-online-shopping-concept-laptop-keyboard-with-shopping-basket-3d-rendering-min.jpg";
import  NewDevelopment from "../../images/brand-images/skills-concept-skills-education-learning-personal-development-competency-business-min.jpg";
import CTA from '../CTA/CTA';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ManufacturersBenefits from '../ForManufactures/ManufacturersBenefits';
import "./PlantAnimalCare.css"
import { Container } from '@material-ui/core';
import PlantAnimalCareProducts from './PlantAnimalCareProducts';
export default function PlantAnimalCare() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forPlantAnimalCare"
        bannerText={["We help Brands to Package Organic and Traceable Products"]}
        href="#ContactUs"
        buttonText="Register now"
      />

      <div className='for-manufacturers-wrapper'>
        <AboutSubSection
          className="rowReverseAbout"
          description="FEN Network specializes in providing contract manufacturing services, catering to diverse product needs. With a proven track record of expertise and reliability, they collaborate closely with brands to bring their concepts to life. From formulation to production and packaging, FEN Network ensures a seamless manufacturing process, delivering high-quality products that meet client specifications."
          title="Contract Manufacturing"
          src={ContractFarmingImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="FEN Network excels in private labeling services, allowing businesses to brand high-quality products as their own. Through this service, brands can leverage GBR's expertise to bring their unique products to market with a personalized touch.
          "
          title="Private Labelling"
          src={PrivateLabelling}
        />
          <AboutSubSection
          className="rowReverseAbout"
          description="FEN Network serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. brands benefit from GBR's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        />

<AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"

          description="FEN Network offers new product development services tailored to brands, helping them innovate and diversify their product offerings to meet evolving market demands."
          title="New Product Development"
          src={NewDevelopment}
        />
          <AboutSubSection
          className="rowReverseAbout"
          description="FEN Network provides brands with the service of expanding their product sales into both local and global marketplaces, leveraging their expertise for effective market penetration.
          ."
          title="onMarketplace"
          src={Market}
        />

              <PlantAnimalCareProducts/>

        <Container className='mt-4'>
          <ManufacturersBenefits />

        </Container>
        <Container className=" subSectionWrapper">
          <CTA
            href="http://localhost:3001/auth/undefined/signup"
            heading="Want to access FEN Network Services"
            btnText="Register Now"
          />
        </Container>   
        
           </div>
      <FooterComponent />
    </>
  )
}
