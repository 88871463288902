import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
// import "./OnMarketplaceSell.css";
// import MarketPlaceQuote from "../MarketPlaceQuote/MarketPlaceQuote";
// import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";

import { useLocation, useHistory } from "react-router-dom";

export default function BuyMarketPlaceSell(){
  
  let navData = [
    { to: "/asset-marketplace", link: "For Farm Enterprise" },
    { to: "/asset-marketplace-sell", link: "For Sellers" }
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

    return(
        <>
        <HeaderComponent className="noShadow"/>
        <SecondaryNavbar props={navData}/>
        <UpdatesBanner 
         className="buy-asset-marketplace-bg text-white"
         bannerText="Start selling agri-assets with FEN"
        
        />

<div className="subSectionWrapper">


<HomeSubSection
className="rowReverseAbout--mod borderedCards--mod borderedCards"
className3="image29"
description="Processing machinery companies partnered with the FEN network can sell a variety of advanced equipment. This partnership helps them provide high-quality, innovative machinery to improve efficiency and productivity in agri-industry, reaching a wider market globally."
descriptionTitle="
Processing Machinery"
href2="auth/3/signup"
buttoncont2="Register"
onClick={() => {
    localStorage.setItem("userType", "PMC");
  }}
  description1="Food Processing Machinery | Feed Processing Machinery"
/>

<HomeSubSection
className="rowReverseAbout borderedCards--mod borderedCards"
className3="image28"
description="Farming machinery companies partnered with the FEN network can sell a range of farming equipment. This partnership allows them to offer high-quality, innovative machinery to improve agricultural efficiency and productivity, reaching a broader market."
descriptionTitle="
Farming Machinery"
href2="auth/3/signup"
buttoncont2="Register"
onClick={() => {
    localStorage.setItem("userType", "FMC");
  }}

  
/>


<HomeSubSection
  className="rowReverseAbout--mod borderedCards--mod borderedCards"
  className3="agri-asset"
  description="Agri-asset companies partnered with the FEN network can sell various agricultural assets, such as bee colonies and livestock. This partnership helps them offer high-quality, essential resources to enhance agricultural productivity and reach a wider market."
  descriptionTitle="
Farming Assets"
href2="auth/3/signup"
buttoncont2="Register"
onClick={() => {
    localStorage.setItem("userType", "AssetDeveloper");
  }}
  description1="Agri Asset | Animal Asset"
/>

</div>
        <FooterComponent/>
        </>
    )
}