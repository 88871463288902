import React from "react";
import HoneyAsset from "../../images/1 (1).png";
import Community from "../../images/transparent-plastic-bottles-filled-with-yellow-substance (4).jpg";
import QualityAssurance from "../../images/quality-assurance.jpg";
import PO from "../../images/Po/main.jpg";
import Provenance from "../../images/agronomist-examines-growing-melon-seedlings-farm-farmers-researchers-analysis-plant.jpg";
import CollaborativeFarming from "../../images/collaborative-farming.jpg";

import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default function FEServices() {
  const services = [
    // {
    //   title: "Manufacturing onDemand (MoD)",
    //   image: Community,
    //   href: "/MoD",
    // },

    // {
    //     title: "onMarketplace",
    //     image: HoneyAsset,
    //     href: "https://web.gbrapp.com/onMarketplace",
    //   },

    //   {
    //     title: "Social onMarketplace",
    //     image: FieldSupport,
    //     href: "https://web.gbrapp.com/social-cf-onmarketplace",
    //   },

    {
      title: "PO Tracking & Monitoring",
      image: PO,
      href: "/CF-PO-Services",
      customPropValue: "food processing enterprise",

    },
    {
      title: "Farming Provenance",
      image: Provenance,
      href: "/Provenance",
      customPropValue: "food processing enterprise",
    },
    {
      title: "Quality Assurance",
      image: QualityAssurance,
      href: "/quality-assurance",
    },

    {
        title: "Collaborative Farming",
        image: CollaborativeFarming,
        href: "/collaborative-farming",
      },

  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper px-0 pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
        Farm Enterprise Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          const navLinkProps = {
            to: { pathname: e.href },
          };

          if (e.customPropValue) {
            navLinkProps.to.state = { customProp: e.customPropValue };
          }
          return (
            <Col md={3}>
              <NavLink {...navLinkProps}>
                <div className="image-container mb-4">
                  <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
