import React from "react";
import "./POServices.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import PrivateLabelling from "../../images/Po/status.jpg";
import NewDevelopment from "../../images/Po/paperless.jpg";
import BrandCTA from "../BrandCTA/BrandCTA";
export default function POServices(props){

  const customer = props.location.state.customProp ;

    return(
        <>
        <HeaderComponent/>
        <UpdatesBanner 
         className="POBanner text-white"
         bannerText="CMN Service"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
      <div className="for-manufacturers-wrapper">
        
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description={`Farm enterprise extends its service of Work Order Status to ${customer}, offering real-time monitoring and updates on the progress of their orders throughout the farming cycle. By leveraging advanced tracking systems and communication channels, Farm enterprise enables ${customer} to stay informed about the status of their work orders, ensuring timely delivery, mitigating potential delays, and fostering efficient collaboration between all parties involved in the farming process.`}
          
          title="PO Monitoring"
          src={PrivateLabelling}
        />
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="Farm enterprise serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. ${customer} benefit from Farm enterprise's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        /> */}
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout"
          description={`Farm enterprise delivers a Paperless Process service to ${customer}, streamlining operations by digitizing workflows and eliminating the reliance on traditional paper-based documentation. Through the integration of digital tools and platforms, Farm enterprise facilitates seamless communication, data management, and task execution, enabling ${customer} to enhance efficiency, reduce environmental impact, and optimize resource utilization while ensuring compliance with industry regulations and standards.`}
          title="Paperless Process"
          src={NewDevelopment}
        />
        {/* <BrandCTA/> */}
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="Farm enterprise provides ${customer} with the service of expanding their product sales into both local and global marketplaces, leveraging their expertise for effective market penetration.
          "
          title="onMarketplace"
          src={Market}
        /> */}
</div>

        <FooterComponent/>
        </>
    )
}