
import React from "react";
import FieldSupport from "../../images/socialmarketplace.jpg";
import BillingService from "../../images/marketplace/billing-service.jpg";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default function ManufacturerServices() {
  const services = [
    // {
    //   title: "Manufacturing onDemand (MoD)",
    //   image: Community,
    //   href: "/MoD",
    // },
   
    // {
    //     title: "onMarketplace",
    //     image: HoneyAsset,
    //     href: "/onMarketplace",
    //   },

      {
        title: "Social CFonMarketplace",
        image: FieldSupport,
        href: "/social-cf-onmarketplace",
      },
      {
        title: "Billing Services",
        image: BillingService,
        href: "/billing-service",
      },

      // {
      //   title: "CMN",
      //   image: PO,
      //   href: "/CF-PO-Services",
      // },
      // {
      //   title: "Farming Provenance",
      //   image: Provenance,
      //   href: "/Provenance",
      //   customProp:"brands"

      // },
      // {
      //   title: "DSN",
      //   image: PO,
      //   href: "/dsn-service",
      // },
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper px-0 pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {

// const navLinkProps = {
//   to:{
// pathname: e.href
//   }
// }

// if(e.customPropValue){
//   navLinkProps.to.state = {customProp:e.customPropValue}
// }

const navLinkProps ={
  to:{
    pathname:e.href
  }
}

if(e.customProp){
  navLinkProps.to.state = {customProp:e.customProp}
}
          return (
            <Col md={5}>
              <NavLink {...navLinkProps}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
