import React from "react";
// import "./ManufacturingOnDemand.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/white-label1.jpg";
import PrivateLabelling from "../../images/brand-images/private-labelling.jpg";
import NewDevelopment from "../../images/brand-images/skills-concept-skills-education-learning-personal-development-competency-business-min.jpg";
import BrandCTA from "../BrandCTA/BrandCTA";
export default function LogisticsProvidersFleetServices(){
    return(
        <>
        <HeaderComponent/>
        <UpdatesBanner 
         className="MoDBanner text-white"
         bannerText="Fleet Services"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
      <div className="for-manufacturers-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          description="
          FEN Network offers Transport Asset Management services, ensuring efficient utilization and maintenance of transport assets such as vehicles, equipment, and facilities. This comprehensive service optimizes asset performance, reduces downtime, and enhances overall operational efficiency in the logistics ecosystem."
          title="Transport Asset Management"
          src={ContractFarmingImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="FEN Network provides Trip Planner services, facilitating efficient route planning and scheduling for transportation operations. This service optimizes logistics processes, minimizes fuel consumption, and enhances delivery reliability by identifying the most efficient routes and managing trip itineraries effectively.
          "
          title="Trip Planner"
          src={PrivateLabelling}
        />
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="FEN serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. brands benefit from FEN's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        /> */}
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout"
          description="FEN Network offers Live Monitoring services, providing real-time tracking and visibility into shipments, vehicles, and assets throughout the transportation journey. This service enables proactive decision-making, enhances operational efficiency, and ensures timely and secure deliveries by continuously monitoring activities and events in the logistics network.
          "
          title="Live Monitoring"
          src={NewDevelopment}
        />
         <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="FEN Network offers Geofencing services, enabling the creation of virtual boundaries or geofences around specific locations or routes. This technology allows for automated alerts and notifications when vehicles or assets enter or exit predefined areas, enhancing security, compliance, and operational efficiency in logistics operations.
          "
          title="Geofence"
          src={PrivateLabelling}
        />
        {/* <BrandCTA/> */}
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="FEN provides brands with the service of expanding their product sales into both local and global marketplaces, leveraging their expertise for effective market penetration.
          "
          title="onMarketplace"
          src={Market}
        /> */}
</div>

        <FooterComponent/>
        </>
    )
}