import React from "react";
import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../images/gbrHome/advisor.webp";
import InvestorImg from "../../images/gbrHome/investor.webp";
import MachineryImg from "../../images/gbrHome/machinery.webp";
import ExporterImg from "../../images/gbrHome/exporter.webp";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import FEPartnersBenefit from "../ForAAMC/FEPartnersBenefit";


export default function FEPartner(props) {
  const exploreButton = props.exploreBtn;
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="AAMCBanner" bannerText="Farm Enterprise Vendors"/>
    <Container>
    <FEPartnersBenefit/>
    </Container>
    <div className="subSectionWrapper main-wrapper2">
      <Container>
        <h4 className="container-title text-center mx-md-4 ">
          
          Vendors
        </h4>

     
      </Container>
   

 


    
      <HomeSubSection
        id="FEN-Advertisement"
        className3="image3"
        className="rowReverseAbout borderedCards"
        description="Agri Input Providers are key vendors of farm enterprises, supplying essential agricultural inputs like seeds, fertilizers, and equipment. Their support ensures that farm enterprises have the necessary resources to optimize production and achieve higher yields.
                "
        descriptionTitle="
                Agri Input Providers & Agrodealers" 
        description1="FMC | PMC | AHSP | AIC | Feed Processing Machinery Company"
        src={MachineryImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/20/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Machinery Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/agri-input-providers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />

    




 
      <HomeSubSection
        id="FEN-Transporters"
        className3="image5"
        className="rowReverseAbout--mod borderedCards"
        description="Logistic Providers are vendors of farm enterprises, responsible for transporting agricultural products and inputs. They ensure efficient and timely delivery, supporting smooth operations across the supply chain.
        "
        // description="FEN  Platform provides logistics faeture. With FEN  Platform, logistics companies and their staff can manage their entire fleet, booking details, trips scheduling, scheduling loads & orders to their own drivers or 3rd party drivers."
        descriptionTitle=" Logistic Providers"
        src={MSMEImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/13/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore  Logistic Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/logistic-providers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "LogisticProvider");
        }}
        description1= "Global Logistic Providers | Hyperlocal Transporters | Cold Chain Logistics Providers "
      />


    


      





   


    
    










      
     



    </div>
<FooterComponent/>
    </>
  );
}
