
import React from "react";
import HoneyAsset from "../../../images/agriproducer-img/Services/businessman-counting-money-japanese-yen-banknotes-while-making-agreement-min.jpg";
import HoneyAsset1 from "../../../images/forMSMEBanner.webp";

import ProcessingSupport from "../../../images/agriproducer-img/Services/front-view-jar-with-dirt-stacked-coins-with-plant-min.jpg"
import OnBoardSupport from "../../../images/agriproducer-img/Services/agronomists-doing-quality-control-hemp-field-min.jpg"
import { NavLink } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

export default function FarmersServices() {
  const services = [
    {
        title: "Financial Services",
        image: HoneyAsset,
        href: "/financial-services",
      },
   
    {
        title: "Asset Market Services",
        image:  ProcessingSupport,
        href: "/asset-market-services",
      },

      // {
      //   title: "Social onMarketplace",
      //   image: FieldSupport,
      //   href: "social-cf-onmarketplace",
      // },

      {
        title: "Care Services",
        image: OnBoardSupport,
        href: "/care-services",
      },

      
  ];

  return (
    <div className="container for-farmer-section2 px-0 subSectionWrapper pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
      Agribusiness (CBO & Agro-Dealer) Services for Agri-Producers
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={4}>
              <a href={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </a>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
