import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function AgroCareMarketPlaceNavbar() {
    const navData = [
    {to:"/agrocare-marketplace", link:"For Farm Enterprise"},
    {to:"/for-agrocare-market-seller", link:"For Seller"},
  ]
//   const navData2 = [{to:"/AgriBusiness", link:"Agri-Business"},
//   {to:"/Brands", link:"Brands"},
// ]
  return (
    <><SecondaryNavbar props={navData}  /></>
  )
}
