import React from "react";
import NetworkBanner from "../WebSite/NetworkBanner/NetworkBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import CommunitySection from "../WebSite/CommunitySection/CommunitySection";
import ABNServices from "../OurServices/ABNServices";
import AgribusinessMainSection from "../AgribusinessMain/AgribusinessMainSection"
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import FEN from "../../images/Customized ABN network (FEN) Infographic - 21-08-2024 C.gif";
import ABNBenefits from "../WebSite/Benefits/ABNBenefits";
import ABNNetworkOperator from "../ABNNetworkOperator/ABNNetworkOperator";
import { Col, Container, Row } from "react-bootstrap";
import AgribussinessAdditional from "../AgribusinessMain/AgribussinessAdditional";
import NetworkOperatorBenefits from "../ForNetworKOperators/NetworkOperatorBenefits";
import ABNNetworkBenefits from "./ABNNetworkBenefits";
import ABNMainServices from "./ABNMainServices"
export default function ABNComponent() {
  return (
    <>
      <HeaderComponent />
      <NetworkBanner bannerTitle=" Farm Enterprise Network (FEN) is to create a Digital Trust among farm enterprises and partners" bodycopy="Digital Trust | Digital Transformation | Build your own Community" className="abnbg" />
     
      <div className="mt-5 mb-5 ">
      <h4 className="container-title text-center mx-md-4 ">
      Managed Farm Enterprise Network</h4> 
      <Container>
        <Row className="justify-content-center">
          <Col md={6} sm={12}>
          <img
              className=" w-100 h-100 cursor-initial"
              src={FEN}
              alt="Infographics"
            />
          </Col>
        </Row>
      </Container>
      
     
      </div>
      <ABNMainServices/>
      <div className="">

      <ABNServices/>
</div>

<div className='main-wrapper2 pb-0 '>
           <Container className="subSectionWrapper">
           <ABNNetworkBenefits/>
        </Container>
        </div>

      <div className="">
        <div className="subSectionWrapper">
          <h4 className="container-title text-center mx-md-4 ">
          Network Beneficiaries & Their Services

          </h4>
          <AgribussinessAdditional/>
        </div>
      </div>


      <div id="ExploreCommunity" className="main-wrapper2">
        <CommunitySection exploreBtn="true" />
      </div>
     

      {/* <ABNNetworkOperator/> */}
      <FooterComponent />
    </>
  );
}
