import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon10 from "../../images/global-market.svg";

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function GrowerBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Grow agri-assets & Earn more",
      bodycopy:
        "Cultivate and manage agricultural assets on behalf of a third party, leveraging expertise to optimize growth and enhance returns.",
    },
    // {
    //   image: Icon10,
    //   title: "Enterprise buy-back support ",
    //   bodycopy:
    //   "Enterprise buy-back support provides agri-entrepreneurs with the assurance that their produce will be bought by the farm enterprise, alleviating concerns about selling their produce."
    // },
    // {
    //   image: Icon6,
    //   title: "Access to Machinery ",
    //   bodycopy:
    //     "Progressive farmers & micro small organisations benefit from facilitated access to machinery, enhancing operational efficiency and productivity."
    // },
    {
      image: Icon7,
      title: "Monetize unutilized farming capacity ",
      bodycopy:
        "Monetizing unutilized farming capacity through agri-asset management and contract farming  services  to leverage their resources for additional income and increased profitability"
    },
    {
      image: Icon8,
      title: "Access to Market",
      bodycopy:
        "Access to local and global markets empowers agri-entrepreneurs with expanded opportunities for sales and economic growth.  "
    },
  ];
  return (
    <>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Farm Enterprise End Users Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </>

  );
}
