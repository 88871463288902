import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "react-bootstrap";
import FFPricingData from "../pricingTabs/FFPricingData";
import PricingTabs from "../pricingTabs/PricingTabs";

export default function AgriProducerPricing() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="pricing-banner text-white"
        bannerText="Agri-Entrepreneur Pricing"
      />
      <Container className="subSectionWrapper">
       <PricingTabs/>
      </Container>
      <FooterComponent />
    </>
  );
}
