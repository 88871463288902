import React from "react";
import "./POServices.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/Po/traceability.jpg";
import PrivateLabelling from "../../images/Po/status.jpg";

import BrandCTA from "../BrandCTA/BrandCTA";
export default function Provenace(props){

  const customer = props.location.state.customProp ;
    return(
        <>
        <HeaderComponent/>
        <UpdatesBanner 
         className="ProvenanceBanner text-white"
         bannerText="Provenance"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
      <div className="for-manufacturers-wrapper">
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="Farm enterprise offers manufacturing process traceability as a service to ${customer}, ensuring transparency and accountability throughout the production chain. By meticulously documenting every stage of the manufacturing process, from sourcing raw materials to final packaging, Farm enterprise empowers ${customer} to uphold quality standards, meet regulatory requirements, and build consumer trust by providing comprehensive insights into product origins and handling."
          title="Manufacturing Process Traceability"
          src={ContractFarmingImg}
        /> */}
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description={`Farm enterprise offers Farm Process Traceability as a service to ${customer}, providing comprehensive oversight of agricultural practices from seed to harvest. Through meticulous tracking and documentation, Farm enterprise ensures transparency and accountability in farming operations, enabling ${customer} to verify the integrity of their supply chain, uphold quality standards, and build consumer trust by offering insights into the origin and cultivation practices of their products.
          `}
          title="Farm Process Traceability"
          src={PrivateLabelling}
        />
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="Farm enterprise serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. ${customer} benefit from Farm enterprise's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        /> */}
    
        {/* <BrandCTA/> */}
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="Farm enterprise provides ${customer} with the service of expanding their product sales into both local and global marketplaces, leveraging their expertise for effective market penetration.
          "
          title="onMarketplace"
          src={Market}
        /> */}
</div>

        <FooterComponent/>
        </>
    )
}