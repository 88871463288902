import React from "react";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import FarmersNeed from "./FarmersNeeds";
import FarmersServices from "./FarmersServices";
import "./Farmers.css"

export default function Farmers() {
  return (
    <>
      <HeaderComponent />
        <UpdatesBanner className="ap-farmers" bannerText="Farmers" />
        <FarmersNeed/>
        <FarmersServices/>


      <FooterComponent />
    </>
  );
}
