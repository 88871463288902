import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function IaaMarketNavbar() {
    const navData = [
        { to: "/iaaMarketplace", link: " For Farm Enterprise" },
        { to: "/social-iaaMarketplace-for-institutional-lenders", link: " For Institutional Lender " },
        { to: "/RetailInvestors", link: " For Retail-Investors " },
        // { to: "/ForFMP", link: " For Farm Machinery Providers " },

      ];
  return (
    <>      <SecondaryNavbar props={navData} />
    </>
  )
}
