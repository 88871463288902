import React from "react";
import FarmerImg from "../../../images/gbrPN/PNImg1.webp";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import PNBenefits from "../Benefits/PNBenefits";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import PN from "../../../images/DTN-network.gif";
import OperatorImg from "../../../images/gbrPN/PNImg3.webp";
import QAImg from "../../../images/gbrPN/PNImg2.webp";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import ExecutiveImg from "../../../images/gbrPN/PNImg4.webp";
import HeaderComponent from "../Header/HeaderComponent";
import NetworkBanner from "../NetworkBanner/NetworkBanner";

import FooterComponent from "../Footer/FooterComponent";
import IAABenefit from "../IAABenefit/IAABenefit";
import IAAManagement from "../IAAManagement/IAAManagement";
import PNServices from "../../OurServices/PNServices";
import ExploreTechnologySection1 from "../../ExploreTechnology/ExploreTechnologySection1";

export default function ProductionNetwork() {
  return (
    <>
      <HeaderComponent />
      {/* <NetworkBanner
        bannerTitle="Network for Agrifood MSMEs to Monetize the unutilized capacities to maximize the profit"
        bodycopy="Job creation | Digital transformation | Brand differentiation | Investable asset management | Organic certification"
        className="pnbg"
      /> */}


<UpdatesBanner
        className="pnbg text-white"
        bannerTitle ="Monetize Platform"
        bannerbodycopy1={[
          // "Introduce new line of business to manage assets and ",
          // <br className="d-lg-block d-none"></br>,
          // "Create your own network of Rural-Entrepreneur",

          "Network for Agrifood MSMEs to Monetize the unutilized capacities ",   <br className="d-lg-block d-none"></br>,"to maximize the profit",
        ]}
        demo="true"
        // bannerbodycopy="Local Trade | Quality Inputs | Insurance | Advisory | Capital"
        // bannerbodycopy={[
        //   "New Revenue Stream | Optimised resource management | Boosts agricultural productivity |",
        //   <br className="d-lg-block d-none"></br>,
        //   "Unlocks opportunities for investors and rural entrepreneurs",
        // ]}
        // buttonText2="Sign in"
        // href2="http://abn.gbrfarming.com/"
        // buttonText3="Sign up"
        // href3="http://abn.gbrfarming.com/auth/3/signup
      />

<div className="mt-5 mb-5">
<h4 className="container-title text-center mx-md-4 ">
DTN Network  Benefits          </h4> 
        <HomeSubSection
          fontBold="true"
          className1="aboutBgGrey"
          className="rowReverseAbout--mod align-items-center"
          listItems={<PNBenefits/>}
          description="  Production network also called Digital Transformation Network (DTN) is designed to manage investable assets by agribusiness for following benefits
          "
          // descriptionTitle="Production Network (PN) & Benefits"
          src={PN}
          href1="/DTNNetwork"
          buttoncont1="Explore Production Network"
          grid="true"
          btn2= "true"

        />
      </div>

<div className="main-wrapper2">
      <PNServices title="DTN Services for Agri-MSMEs" />
      </div>
      <div className="network-wrapper">
        <h2 className="container-title text-center mt-4">
           BENEFICIARIES OF THE DTN NETWORK
        </h2>

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image11"
          description="GBR's agri-entrepreneurs are the backbone of our network, they're the progressive farmers who meet our rigorous verification process, ensuring the quality and reliability of the produce in our eco-system.
          "
          descriptionTitle="
              Agri-entrepreneur"
          src={FarmerImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        />

 

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image15"
          description="GBR's agri-entrepreneurs are the backbone of our network, they're the progressive farmers who meet our rigorous verification process, ensuring the quality and reliability of the produce in our eco-system.
              "
          descriptionTitle="
              Contract Manufacturer "
          src={ProductionManagerImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        />
{/* 
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="imageStrCommittee"
          descriptionTitle="
              Steering committee (Team) Role"
          description="GBR is a technology company based in Bangalore that
      provides an alternate innovate options for Agribusiness
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
          src={ExecutiveImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        /> */}

     

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="agri-storage-entreprenuer-role"
          description="Agri-storage entrepreneurs play a pivotal role by monetizing their storage capacity, offering valuable space to agri-entrepreneurs. Their strategic management of resources adds a crucial dimension to the success of our agricultural network.
              "
          descriptionTitle="
              Agri-Storage Entreprenuer"
          src={ProductionManagerImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        />

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="agri-transporter-role"
          description="FEN Network Agri-transporters are the backbone of our agricultural network, ensuring efficient product movement and optimal resource utilization. With access to our dynamic marketplace, they can monetize their transportation capacity effectively, contributing to the sustainable growth of the agri-industry.
              "
          descriptionTitle="
              Agri Transporter"
          src={ProductionManagerImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        />



</div>

<div className="subSectionWrapper main-wrapper2">
<h2 className="container-title text-center ">
          DTN NETWORK OPERATORS       </h2>
        


        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards"
          className3="agri-foodmanufacturer-img"
          description="Food Processing Enterprises are companies involved in processing agricultural products into consumable goods, playing a crucial role in the production and transformation of raw materials such as crops and livestock into various food products. Examples include those producing packaged foods, beverages, meat, dairy, and processed agricultural goods.
              "
          descriptionTitle="
          Food Processing Enterprises

          "
          src={ProductionManagerImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        />
      </div>
      <FooterComponent />
    </>
  );
}
