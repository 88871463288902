import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
// import "./InstitutionalInvestors.css";
import { Container } from '@material-ui/core';
import IaaMarketNavbar from '../IaaMarketNavbar/IaaMarketNavbar';
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
export default function RetailInvestors() {
   

  return (
    
    <>
    <HeaderComponent className="noShadow"/>
    <IaaMarketNavbar/>
    <UpdatesBanner
        className="IAAMarketplaceBanner text-white"
        bannerText="Invest on tangible assets for better return "
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
      />

        
      <div className="subSectionWrapper">
        <Container>
      <h3 id="agri-assets" className="container-title text-center ">
                Agri Assets
              </h3>
              <ProcessedProducts
                type="AGRI-ASSETS"
                userType={["AssetDeveloper"]}
                user = "AssetOEM"
                invest = "true"
              /> 
    </Container>
      </div>


      <div className="subSectionWrapper">
        <Container>
      <h3 id="agri-assets" className="container-title text-center ">
                Agri Asset Projects
              </h3>
              <ProcessedProducts
                type="FARMING-PROJECTS"
                userType={["AssetDeveloper"]}
                user = "AssetOEM"
                invest = "true"
              /> 
    </Container>
      </div>
        <FooterComponent/>

    </>
  )
}
