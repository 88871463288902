import React, { useState } from "react";
import { Container } from "@material-ui/core";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import "./PricingTabs.css";
import PricingBanner from "../PricingBanner/PricingBanner";
import Tabs from "react-simply-tabs";
import GrowerPricingData from "./GrowerPricingData";
import FFPricingData from "./FFPricingData";
import BrandPricingData from "./BrandPricingData";
import AssetDeveloperPricing from "./AssetDeveloperPricing";

export default function PricingTabs() {
  const [activeTabIndex, setTabIndex] = useState(0);

  return (
    <>
      {/* <HeaderComponent />
      <PricingBanner />
      <Container className="subSectionWrapper"> */}
        <div className="Iaa-tabs Iaa-producers-tab Iaa-producers-tab-pricing">
          {/* <h2 className="container-title text-center">Money-making Assets</h2> */}
          <p className="container-para"></p>

          <Tabs
            id="test"
            activeTabIndex={activeTabIndex}
            activeControlProps={{
              className: "active",
            }}
            onRequestChange={setTabIndex}
            controls={[
            
              // <button type="button" title="" className="Iaa-tab-buttons">
              //   Agri-Entrepreneur
              // </button>,
              // <button type="button" title="" className="Iaa-tab-buttons">
              //   Brands
              // </button>,
              <button type="button" title="" className="Iaa-tab-buttons">
                Grower
              </button>,
                <button type="button" title="" className="Iaa-tab-buttons">
                Farmer Franchise
              </button>,
            ]}
          >
              <div>
              <FFPricingData />

            </div>
            <div>
              <GrowerPricingData />

            </div>
            {/* <div>
              <BrandPricingData />
            </div> */}
            {/* <div>
              <AssetDeveloperPricing />
            </div> */}
         
          </Tabs>
        </div>
      {/* </Container>
      <FooterComponent /> */}
    </>
  );
}
