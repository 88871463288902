import React from 'react'
import AboutList from './AboutList'

export default function ABNSAAS() {
    const data=[
       "Track & Trace:  FEN provides a Track & Trace solution that enables farm enterprises to monitor and trace agricultural products throughout the supply chain, ensuring transparency, authenticity, and compliance at every stage.",
       "End to End Traceability :  The End-to-End Traceability solution by FEN offers comprehensive monitoring of the entire supply chain, allowing farm enterprises to track products from the source to the consumer, enhancing accountability and product integrity. ",
      "Enterprise Agri-Asset Management: FEN's Enterprise Agri-Asset Management solution allows farm enterprises to efficiently manage agricultural assets such as equipment, livestock, and crops, optimizing their usage, maintenance, and operational value across the entire farming cycle.",
      "Farm Management: The Farm Management solution provided by FEN equips farm enterprises with the tools and technology to oversee day-to-day farming operations, streamline productivity, monitor field activities, and enhance overall farm performance."
       
    ]
  return (
   <AboutList title="Solutions" contentlist={data}/>
  )
}